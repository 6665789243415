<template>
  <EditableFrame
    class="send-email-wrapper"
    title="Edit Action"
    :subtitle="item.name"
    has-save-button
    :save-button-disabled="savedButtonDisabled"
    @close="$emit('close')"
    @save="handleSave"
  >
    <am2-create-message-list-modal
      :is-show="displayCreateMessageListModal"
      @close="handleCreateMessageListModalClose"
      :isAutomation="true"
    />
    <am2-email-editor-modal
      :opened="displayEmailEditorModal"
      location="automation"
      :template-type="emailEditorModalTemplateType"
      :default-template="emailTemplate"
      :message-list-oid="selectedListOid"
      :message-type="selectedType"
      @update="handleEmailEditingUpdate"
      @cancel="handleEmailEditingCancel"
      @select="handleSelectEmailTemplateSelect"
      @setEmailEditor="setEmailEditorModalTemplateType"
    />
    <am2-email-preview-modal
      :opened="displayEmailPreviewModal"
      has-mode-tabs
      :subject="subject"
      :preview-text="previewText"
      :sender-name="senderName"
      :business-address="businessAddress"
      :email-html="emailHtmlWithInfo"
      @close="handleEmailPreviewClose"
      :automation-block-oid="blockOid"
      show-share-link
    />
    <!-- Type -->
    <ar-field>
      <div class="type-section">
        <ar-text
          class="u-margin-bottom-3"
          size="14px"
          text="What type of message are you sending?"
          weight="bold"
          multiple-lines
          :max-lines="1"
        />
        <am2-button-switch
          :value="selectedType"
          :items="types"
          @update="handleUpdateSelectedType"
        />
        <ar-text
          class="u-margin-top-3"
          size="13px"
          :text="description[selectedType]"
          multiple-lines
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
      </div>
    </ar-field>
    <!-- Recipient -->
    <ar-field
      v-if="selectedType === 'marketing'"
      :error-messages="[
        veeErrors.first('messageRecipient'),
      ]"
      data-test-id="automation-email-recipient-field"
    >
      <div class="recipient-section">
        <ar-text
          class="label"
          size="14px"
          text="To"
          weight="bold"
          multiple-lines
          :max-lines="1"
        />

        <div class="add-to-list-dropdown-wrapper" @click="toggleOptions" data-test-id="add-to-list-toggle">
          <ar-text
            class="add-to-list-copy"
            size="14px"
            :text="messageListText"
            multiple-lines
            :max-lines="1"
            line-height="20px"
          />
          <ArrowHead
            class="add-to-list-dropdown-arrowhead"
            :class="listOpen && 'rotate'"
            height="8px"
            width="10px"
            fill="#8492a6"
          />
        </div>
        <div v-if="listOpen" class="list-search-wrapper">
          <am2-search
            class="add-to-list-search"
            v-model="listSearchText"
            placeholder="Search lists..."
            data-test-id="add-to-list-search"
          />
          <div class="message-list-wrapper">
            <div v-if="noMatchingLists" class="message-list-link no-list-found-wrapper">
              <div class="item-wrapper">
                <ar-text
                  class="item-name"
                  :style="{ marginRight: '4px' }"
                  size="xs"
                  text="No matching list found"
                  multiple-lines
                  :max-lines="1"
                  line-height="20px"
                  weight="normal"
                />
              </div>
            </div>
            <template v-for="list in filteredMessageList">
              <a
                :key="list.oid"
                class="message-list-link"
                @click="() => setSelectedListOid(list.oid)"
                :data-test-id="`add-to-list-anchor-${list.oid}`"
              >
                <div class="item-wrapper">
                  <ar-text
                    :class="[
                      'list-name',
                      list.oid === selectedListOid && 'selected'
                    ]"
                    size="xs"
                    :text="list.name"
                    multiple-lines
                    :max-lines="1"
                    line-height="20px"
                    :weight="list.oid === selectedListOid ? 'bold' : 'normal'"
                  />
                </div>
              </a>
            </template>
          </div>
        </div>
        <template v-if="isTriggerMessageListMatching">
          <ar-text
            text="Automated marketing message recipients must be subscribed to a list"
            size="xxs"
            multiple-lines
            :style="{
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
        </template>
        <template v-else>
          <ar-snackbar
            type="warning"
            message="Your list does not match your trigger list. Unless recipients are in both lists, they will not receive an email."
            class="u-margin-top-0"
          />
        </template>
        <div class="item-wrapper u-padding-top-4">
            <ar-simple-button
              class="create-list-button"
              text="Create New List"
              outlined
              @click="handleCreateListClick"
            />
        </div>
      </div>
    </ar-field>

    <div v-if="emailSendLimitEnabled"
         class="ignore-email-send-limit u-margin-bottom-8"
         :style="{
            padding: '16px',
            backgroundColor: $arStyle.color.purple100,
            borderRadius: '4px'
         }" >
      <div class="u-display-flex">
        <ar-icon
          class="u-margin-right-2"
          name="notification-general"
          height="20px"
          :color="$arStyle.color.purple500"
        />
        <ar-state-message
          type="info"
          text="You have enabled email send limit"
        />
      </div>
      <ar-checkbox
        v-if="emailSendLimitEnabled"
        class="u-margin-top-3"
        label="Ignore <a href='/settings/email-send-limit' target='blank'>email send limit</a>"
        align="left"
        v-model="ignoreEmailSendLimit"
        data-test-id="`message-ignore-email-send-limit-checkbox`"
      />
    </div>


    <!-- Sender -->
    <ar-field
      :error-messages="[
        veeErrors.first('messageSender'),
      ]"
      data-test-id="automation-email-sender-field"
    >
      <div class="sender-section">
        <ar-text
          class="label"
          size="14px"
          text="From"
          weight="bold"
          multiple-lines
          :max-lines="1"
        />
        <am2-message-sender-select
          class="sender-select"
          :value="messageSenderOid"
          v-validate="'required'"
          data-vv-name="messageSender"
          data-vv-as="message sender"
          @select="handleMessageSenderSelect"
        />
        <ar-snackbar
          v-if="emailAddressIsRestricted"
          type="warning"
          message="<b>Domains from email providers like @gmail.com are invalid. <a>Please create a new authenticated domain</a>.</b>"
          class="u-margin-top-4"
          @anchorClick="handleDomainAuthClick"
        />
        <ar-snackbar
          v-if="domainIsRestricted"
          type="warning"
          message="<b>Your sender domain is not authenticated. <a>Please authenticate this domain</a>.</b>"
          class="u-margin-top-4"
          @anchorClick="handleDomainAuthClick"
        />
        <ar-snackbar
          v-if="messageSenderNotVerified"
          type="general"
          message="Please verify this email address. <a>Resend verification email</a>."
          @anchorClick="retryEmailVerification"
          :style="{
            marginTop: '10px',
            width: '100%',
          }"
        />
      </div>
    </ar-field>
    <!-- Subject -->
    <ar-field
      :error-messages="[
        veeErrors.first('subject'),
      ]"
      data-test-id="automation-email-subject-field"
    >
      <div class="subject-section">
        <div class="label-section">
          <ar-text
            class="label"
            size="14px"
            text="Subject"
            weight="bold"
            multiple-lines
            :max-lines="1"
          />
          <ar-link-button
            v-if="!displayPreviewLine"
            text="Add preview line"
            @click="handleAddPreviewLineClick"
          />
        </div>
        <ar-input
          class="input"
          v-model="subject"
          v-validate="'required'"
          data-vv-name="subject"
          data-vv-as="subject"
          placeholder="Email subject"
          data-test-id="automation-email-subject-input-field"
        />
      </div>
    </ar-field>
    <!-- Preview Line -->
    <div
      v-if="displayPreviewLine"
      class="preview-line-section"
    >
      <div class="label-section">
        <ar-text
          class="label"
          size="14px"
          text="PREVIEW LINE"
          weight="bold"
          multiple-lines
          :max-lines="1"
          letter-spacing="1px"
        />
        <ar-link-button
          text="Remove preview line"
          @click="handleRemovePreviewLineClick"
          data-test-id="automation-email-remove-preview-link"
        />
      </div>
      <ar-input
        class="input"
        v-model="previewText"
        placeholder="Email preview line"
        data-test-id="automation-email-preview-input"
      />
    </div>
    <!-- Template -->
    <ar-field
      :error-messages="[
        veeErrors.first('emailTemplate'),
        invalidDynamicTagsFallbackError,
      ]"
      data-test-id="automation-email-design-field"
    >
      <div
        v-validate:emailHtmlBucketFieldsIsComplete="'required:true'"
        data-vv-name="emailTemplate"
        data-vv-as="email design"
      />
      <div
        class="design-section"
      >
        <div class="label-section">
          <ar-text
            class="label"
            size="14px"
            text="DESIGN"
            weight="bold"
            multiple-lines
            :max-lines="1"
            letter-spacing="1px"
          />
        </div>
        <template>
          <ar-skeleton
            v-if="isGeneratingEmailHtmlFromTemplate"
            class="html-preview"
            :style="{
              borderRadius: '3px',
            }"
          />
          <am2-html-preview
            v-else-if="emailHtml"
            class="html-preview"
            :html="emailHtmlWithInfo"
            enable-scroll
          />
        </template>
        <div class="action-buttons-section">
          <ar-simple-button
            class="launch-email-button"
            text="Launch email builder"
            outlined
            @click="handleLaunchEmailBuilderClick"
            data-test-id="automation-email-launch-builder-button"
          />
          <ar-simple-button
            v-if="emailHtml"
            class="review-email-button"
            icon-name="preview"
            text="Preview"
            type="grey"
            @click="handlePreviewEmailClick"
            data-test-id="automation-email-preview-button"
          />
        </div>
      </div>
    </ar-field>
  </EditableFrame>
</template>
<script>
import EditableFrame from '../utils/EditableFrame.vue'
import ArrowHead from '~/assets/icons/arrow-head.svg?inline'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import {convertObjectKeysToCamelCase} from '~/utils/helpers'
import ignoreEmailSendLimit from "~/mixins/ignoreEmailSendLimit";
export default {
  name: 'SendEmail',
  components: {
    EditableFrame,
    ArrowHead
  },
  mixins: [
    ignoreEmailSendLimit
  ],
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      emailTemplate: null,
      emailTemplateType: null,
      emailHtml: null,
      subject: null,
      previewText: null,
      messageSender: null,
      isGeneratingEmailHtmlFromTemplate: false,
      displayPreviewLine: false,
      displayEmailEditorModal: false,
      displayEmailPreviewModal: false,
      emailEditorModalTemplateType: null,
      beefreeGeneratedHtml: null, // Might be able to deprecate this
      savingEmailSettings: false,
      htmlFooter: null,
      htmlPreview: null,


      types: [{
        name: 'Marketing',
        key: 'marketing',
      }, {
        name: 'Transactional',
        key: 'transactional'
      }],
      selectedType: 'marketing',


      // To List
      description: {
        marketing: "Promoting a new event, merchandise or ticket sales. These messages can only be sent to opted in recipients.",
        transactional: "Event updates or important information about an event the recipient has already purchased a ticket to.",
      },
      listSearchText: '',
      listOpen: false,
      selectedListOid: null,
      filteredMessageList: [],
      isTriggerMessageListMatching: true,
    }
  },
  computed: {
    ...mapState({
      promoterOid: state => state.auth.account.promoterOid,
      automationId: state => state.automation.id,
      emailDomainRestricted: state => state.message.emailDomainRestricted,
      domainRestricted: state => state.message.domainRestricted,
      scratchEmailMessage: state => state.message.scratchEmailMessage,
    }),
    ...mapGetters({
      messageLists: 'messageList/userDefinedMessageLists',
      nonExistingDynamicTagsInEmailMessageCopy: 'message/nonExistingDynamicTagsInEmailMessageCopy',
      hasNonExistingTagsInEmailMessage: 'message/hasNonExistingTagsInEmailMessage',
    }),
    messageListValueValid() {
      return this.selectedType === 'marketing' ? this.selectedListOid : true
    },
    savedButtonDisabled() {
      if (this.savingEmailSettings && !this.messageListValueValid) return true;
      return !(this.messageSender && this.subject && this.emailHtml && this.messageListValueValid && !this.invalidDynamicTagsFallbackError)
    },
    senderName() {
      return this.messageSender?.additionalInfo.senderName || null;
    },
    businessAddress() {
      return this.messageSender?.additionalInfo.businessAddress || null;
    },
    messageSenderNotVerified() {
      if (!this.messageSender) {
        return false;
      }
      return !this.messageSender.verified;
    },
    messageSenderOid() {
      return this.messageSender?.oid;
    },
    saveButtonDisabled() {
      return this.messageSenderNotVerified;
    },
    emailHtmlBucketFieldsIsComplete() {
      return !!this.emailTemplate
        && !!this.emailTemplateType
        && !!this.emailHtml;
    },
    emailHtmlWithInfo() {
      if (!this.emailHtml) {
        return null;
      }
      return this.generateEmailHtmlWithInfo(
        this.emailTemplateType,
        this.emailHtml,
        this.previewText,
        this.senderName,
        this.businessAddress,
      );
    },
    blockOid() {
      return this.item?.id;
    },
    emailAddressIsRestricted() {
      return this.emailDomainRestricted;
    },
    domainIsRestricted() {
      return this.domainRestricted;
    },
    messageListText() {
      if (!this.selectedListOid || !this.messageLists?.length) return 'Select message list...'
      return this.messageLists?.find(item => item.oid === this.selectedListOid)?.name
    },
    invalidDynamicTagsFallbackError() {
      if (this.scratchEmailMessage?.meta?.fallbackDynamicTags) {
        for (const key of Object.keys(this.scratchEmailMessage.meta.fallbackDynamicTags)) {
          if (!this.scratchEmailMessage.meta.fallbackDynamicTags[key]) {
            return 'Dynamic tags fallback are required'
          }
        }
      }
      return false
    },
    noMatchingLists() {
      return !this.filteredMessageList?.length
    },
     displayCreateMessageListModal: {
        get() {
            return this.$store.state.automation.displayListModal
         },
         set(value) {
             this.$store.state.automation.displayListModal = value
         }
     }
  },
  watch: {
    'item.id'() {
      this.setItemVals()
    },
    messageLists: {
      immediate: true,
      handler() {
        this.filteredMessageList = this.messageLists
      }
    },
    listSearchText() {
      this.filterLists(this.listSearchText)
    },
  },
  methods: {
    ...mapActions({
      updateActionConfig: 'automation/UPDATE_ACTION_CONFIG',
      resendEmailVerification: 'messageSender/RESEND_EMAIL_VERIFICATION',
      createBeefreeTemplate: 'emailTemplate/CREATE_BEEFREE_EMAIL_TEMPLATE',
      updateBucketEmailTemplate: 'emailTemplate/UPDATE_BUCKET_EMAIL_TEMPLATE',
      downloadBeefreeHtml: 'emailTemplate/DOWNLOAD_BEEFREE_HTML_FROM_TEMPLATE',
      fetchBeefreeTemplate: 'emailTemplate/FETCH_BEEFREE_TEMPLATE',
      checkEmailDomainRestricted: 'message/CHECK_EMAIL_DOMAIN_RESTRICTED',
      fetchManualMessageLists: 'automation/FETCH_MANUAL_MESSAGE_LISTS',
    }),
    ...mapMutations([
      'emailTemplate/RESET_EMAIL_TEMPLATES',
      'message/PATCH_SCRATCH_EMAIL_MESSAGE',
    ]),
    generateEmailHtmlWithInfo(templateType, emailHtml, previewText, senderName, businessAddress) {
      if (templateType === 'unlayer') {
        return this.$arUtils.email.injectInformationToUnlayerHtml(
          emailHtml,
          previewText,
          senderName,
          businessAddress,
          true,
        );
      } else if (templateType === 'beefree') {
        return emailHtml;
      } else {
        return this.$arUtils.email.injectInformationToRichTextHtml(
          emailHtml,
          previewText,
          senderName,
          businessAddress,
          true,
        );
      }
    },
    async generateEmailHtmlFromTemplate(templateType, template, htmlUrl = null) {
      try {
        this.isGeneratingEmailHtmlFromTemplate = true;
        if (templateType === 'unlayer') {
          const unlayerOutput = await this.$api.unlayer.exportHtml(template);
          this.emailHtml = unlayerOutput.html;
        } else if (templateType === 'beefree') {
          const beefreeOutput = await this.$api.beefree.exportHtml(htmlUrl);
          this.emailHtml = beefreeOutput;
        } else if (templateType === 'rich-text') {
          this.emailHtml = template;
        }
      } catch (e) {
        this.$arNotification.push({
          type: 'error',
          message: 'Failed to download email html',
        });
      } finally {
        this.isGeneratingEmailHtmlFromTemplate = false;
      }
    },
    async createEmailHtmlBucket(template, templateType, emailHtmlInfo) {
      // Save the template into the bucket

      const config = this.item?.config;
      const bucketEmailTemplateOid = !!config ? config['bucket-email-template-oid'] : null;

      if (templateType === 'beefree') {
        if (bucketEmailTemplateOid) {
          const update = await this.updateBucketEmailTemplate(
            {
              oid: bucketEmailTemplateOid,
              meta: {
                name: `${this.subject} - Automation ${this.automationId}-${this.item.id}` || `Automation Template ${this.automationId} - ${this.item.id}`,
                template: typeof template === 'string' ? template : JSON.stringify(template),
                templateType: templateType,
                version: 1,
                previewHtml: this.htmlPreview,
                footerHtml: this.htmlFooter,
              }
            });
          return await this.fetchBeefreeTemplate(bucketEmailTemplateOid)
        } else {
          return await this.createBeefreeTemplate({
            meta: {
              name: this.subject || `Automation Template ${this.automationId} - ${this.item.id}`,
              template: typeof template === 'string' ? template : JSON.stringify(template),
              templateType: templateType,
              version: 1,
              previewHtml: this.beefreeGeneratedHtml?.previewHtml || this.htmlPreview,
              footerHtml: this.beefreeGeneratedHtml?.footerHtml || this.htmlFooter,
            },
            automationBlockOid: this.blockOid,
          })
        }
      } else {
        return await this.$api.buckets.createLocalBucket(
          this.promoterOid,
          'email-html',
          'text/html',
          {
            template,
            templateType,
            emailHtml: emailHtmlInfo,
          },
        );
      }
    },
    async handleSave() {
      const valid = await this.validateInputs();
      if (!valid) {
        return;
      }

      // Update the injected preview and footer html
      await this.updateInjection();

      this.savingEmailSettings = true;
      const newBucket = await this.createEmailHtmlBucket(
        this.emailTemplate,
        this.emailTemplateType,
        this.emailHtmlWithInfo,
      );

      this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
        meta: {
          ignoreEmailSendLimit: this.ignoreEmailSendLimit,
        }
      })
      const contextData = {
        config: {
          subject: this.subject,
          previewText: this.previewText,
          promoterPropertyOid: this.messageSender.oid,
          templateType: this.emailTemplateType,
          messageType: this.selectedType,
          linkedMessageListOid: this.selectedType === 'marketing' ? this.selectedListOid : null,
          fallbackDynamicTags: this.scratchEmailMessage?.meta?.fallbackDynamicTags || null,
          dynamicTagHeaders: this.scratchEmailMessage?.meta?.dynamicTagHeaders || null,
          tagsResourceOid: this.scratchEmailMessage?.meta?.tagsResourceOid || null,
          tagMaxWidthMap: this.scratchEmailMessage?.meta?.tagMaxWidthMap || null,
          ignoreEmailSendLimit: this.scratchEmailMessage?.meta?.ignoreEmailSendLimit,
        },
        meta: {
          messageSender: this.messageSender,
          bucket: newBucket,
        },
      };
      if (this.emailTemplateType === 'beefree') {
        contextData.config.bucketEmailTemplateOid = newBucket.oid;
      } else {
        contextData.config.bucketOid = newBucket.oid;
      }

      this.updateActionConfig({
        id: this.item.id,
        data: contextData,
      });
      this.savingEmailSettings = false;
      this.$emit('close');
    },
    async validateInputs() {
      const isValid = await this.$validator.validateAll();
      return isValid && this.invalidDynamicTagsFallbackError === false;
    },
    async setItemVals() {
      const {
        config,
        meta,
      } = this.item;

      if (config?.['promoter-property-oid']) {
        this.messageSender = meta.messageSender;
      }

      if (config?.['template-type'] === 'beefree' && config?.['bucket-email-template-oid']) {
        // If the current bucket is empty and the template type is beefree, we need to retrieve the html from the beefree bucket
        const beefree_template = await this.fetchBeefreeTemplate(config?.['bucket-email-template-oid'])
        this.emailTemplate = JSON.parse(beefree_template?.meta?.template);
        this.emailTemplateType = 'beefree';
        this.emailEditorModalTemplateType = 'beefree';
        this.generateEmailHtmlFromTemplate('beefree', this.emailTemplate, beefree_template?.meta?.url);
      } else if (config?.['bucket-oid'] && meta.bucket.meta) {
        const { template, templateType, url } = meta.bucket.meta;
        this.emailTemplate = template;
        this.emailTemplateType = templateType;
        this.emailEditorModalTemplateType = templateType;
        this.generateEmailHtmlFromTemplate(templateType, template, url);
      }

      if (!this.emailEditorModalTemplateType) {
        this.emailEditorModalTemplateType = 'beefree'
      }

      this.subject = config?.subject || null;
      this.previewText = config?.['preview-text'] || null;
      this.selectedListOid = config?.['linked-message-list-oid'] || null;
      this.selectedType = config?.['message-type'] || 'marketing';
      this.ignoreEmailSendLimit = config?.ignoreEmailSendLimit || false;

      if (this.previewText) {
        this.displayPreviewLine = true;
      }

      if (this.scratchEmailMessage && this.item?.config && this.item?.config['fallback-dynamic-tags']) {
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            fallbackDynamicTags: convertObjectKeysToCamelCase(this.item?.config['fallback-dynamic-tags']),
            dynamicTagHeaders: this.item?.config['dynamic-tag-headers'],
            tagsResourceOid: this.item?.config['tags-resource-oid'],
            tagMaxWidthMap: this.item?.config['tag-max-width-map'],
          }
        })
      }
    },
    retryEmailVerification() {
      this.resendEmailVerification(this.messageSender.oid);
    },
    handleMessageSenderSelect(messageSender) {
      this.messageSender = messageSender;
      this.checkEmailDomainRestricted(messageSender.property)
    },
    handleAddPreviewLineClick() {
      this.displayPreviewLine = true;
    },
    handleRemovePreviewLineClick() {
      this.displayPreviewLine = false;
    },
    handleLaunchEmailBuilderClick() {
      this.displayEmailEditorModal = true;
    },
    async getBeefreeHtml(template) {
      try {
        await this.updateInjection();
        return await this.downloadBeefreeHtml({
          design: template,
          previewHtml: this.htmlPreview || null,
          footerHtml: this.htmlFooter || null,
        })
      } catch (error) {
        console.error("failed to download beefree html", error)
        return null
      }
    },
    async handleEmailEditingUpdate(template, emailHtml) {
      this.displayEmailEditorModal = false;
      let htmlString = emailHtml

      if (this.emailEditorModalTemplateType === 'beefree') {
        htmlString = await this.getBeefreeHtml(template)
      }

      this.emailTemplate = template;
      this.emailTemplateType = this.emailEditorModalTemplateType;
      this.emailHtml = htmlString;
    },
    handleEmailEditingCancel() {
      this.displayEmailEditorModal = false;
    },
    handlePreviewEmailClick() {
      this.displayEmailPreviewModal = true;
    },
    handleEmailPreviewClose() {
      this.displayEmailPreviewModal = false;
    },
    async handleSelectEmailTemplateSelect(template, templateType, emailHtml) {
      let htmlString = emailHtml

      if (this.emailEditorModalTemplateType === 'beefree') {
        htmlString = await this.getBeefreeHtml(template)
      }

      this.emailTemplate = template;
      this.emailTemplateType = templateType;
      this.emailHtml = htmlString;
    },
    setEmailEditorModalTemplateType(templateType) {
      this.emailEditorModalTemplateType = templateType
    },
    async updateInjection() {
      this.beefreeGeneratedHtml = await this.$arUtils.email.getPreviewAndFooterHtml(
        this.previewText,
        this.senderName,
        this.businessAddress,
        true
      )
    },
    generatePreviewAndFooterHtml() {
      const {previewHtml, footerHtml} = this.$arUtils.email.getPreviewAndFooterHtml(
        this.previewText,
        this.senderName,
        this.businessAddress,
        true
      )
      this.htmlFooter = footerHtml;
      this.htmlPreview = previewHtml;
    },
    handleDomainAuthClick() {
      window.open('https://audiencerepublic.freshdesk.com/support/solutions/articles/51000309976-domain-authentication', '_blank');
    },
    handleUpdateSelectedType(typeKey) {
      this.selectedType = typeKey
    },
    toggleOptions() {
      if (this.listOpen) {
        this.listSearchText = ''
      }
      this.listOpen = !this.listOpen
    },
    isTriggerListSubscribed(selectedListOid) {
      return this.$store.state.automation.triggers.items.every(t => t.key !== 'messaging.list.subscribed' || (t.config?.['message-list-oid'] === undefined || t.config?.['message-list-oid'] === selectedListOid))
    },
    setSelectedListOid(oid) {
      this.isTriggerMessageListMatching = this.isTriggerListSubscribed(oid)
      this.selectedListOid = oid
      this.listOpen = false
    },
    filterLists(text) {
      let filteredMessageList = []
      let i = 0
      while (i < this.messageLists.length) {
        if (this.$arFuzzySearch(this.messageLists[i].name, text)) {
          filteredMessageList.push(this.messageLists[i])
        }

        i++
      }

      this.filteredMessageList = filteredMessageList
    },
    handleCreateMessageListModalClose() {
          this.displayCreateMessageListModal = false;
    },
    handleCreateListClick() {
          this.displayCreateMessageListModal = true;
    },
  },
  fetch() {
    this.fetchManualMessageLists()
  },
  mounted() {
    this.setItemVals()
    this.generatePreviewAndFooterHtml();
    this['emailTemplate/RESET_EMAIL_TEMPLATES']()
  }
}
</script>
<style lang="scss" scoped>
.send-email-wrapper {
  width: 100%;
  height: 100%;

  .tooltip {
    cursor: pointer;
  }

  .type-section {
    margin-bottom: 31px;
    .label-section {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .recipient-section {
    margin-bottom: 31px;

    .add-to-list-dropdown-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: ui-px2em(13) ui-px2em(16) ui-px2em(13) ui-px2em(16);
      border-radius: 4px;
      border: solid ui-px2em(1) #dcdee4;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      .add-to-list-dropdown-arrowhead {
        transition: all 0.2s ease;

        &.rotate {
          transform: rotate(540deg);
        }
      }
    }
    .list-search-wrapper {
      width: 100%;
      border-radius: 5px;
      box-shadow: 0 2px 6px 0 #e4e5e7;
      border: solid 1px #d4d8e1;
      background-color: #fff;
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      z-index: 10;
      margin-bottom: 10px;
      position: absolute;

      .add-to-list-search {
        width: 100%;
        height: 60px;
        padding: 9px 12px 0px 9px;
      }

      .message-list-wrapper {
        width: 100%;
        margin-top: 9px;
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        max-height: 260px;
        overflow: auto;
        padding-bottom: 10px;

        .message-list-link {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          padding-left: 8px;
          cursor: pointer;

          &:hover {
            background: #f2edff;
          }

          &.no-list-found-wrapper:hover {
            background: unset;
            cursor: default;
          }

          .item-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding: 12px;

            .list-name {
              &.selected {
                color: $purple500;
              }
            }
          }
        }
      }
    }
    .create-list-button {
      margin-bottom: 10px;
      width: 100%;
      height: 40px;
    }
  }
  .sender-section {

    .sender-select {
      margin-top: 14px;
      width: 100%;
    }
  }
  .subject-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .input {
      margin-top: 14px;
    }
  }
  .preview-line-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .input {
      margin-top: 14px;
    }
  }
  .design-section {
    margin-top: 31px;
    .label-section {
      display: flex;
      align-items: center;
      .label {
        flex-grow: 1;
      }
    }
    .html-preview {
      margin-top: 14px;
      height: 200px;
    }
    .action-buttons-section {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 20px;
      .review-email-button {
        width: 100%;
        height: 40px;
      }
      .launch-email-button {
        margin-bottom: 10px;
        width: 100%;
        height: 40px;
      }
    }
  }
}
</style>
