var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"event-details"},[_c('ar-field',{attrs:{"label":"Event name","error-messages":[_vm.veeErrors.first('event-name')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:150'),expression:"'required|max:150'"}],attrs:{"value":_vm.scratchEvent ? _vm.scratchEvent.name : "","autocomplete":"event-name","data-vv-name":"event-name","data-vv-as":"event name","placeholder":"Event name","type":"text","data-test-id":"event-name"},on:{"input":_vm.handleEventNameInput}})],1),_vm._v(" "),(_vm.scratchEventIsZoomEvent)?_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Location"}},[_c('am2-new-slug-input',{attrs:{"value":_vm.zoomUrlFromCurrentEvent,"readonly":"","enable-copy":""}})],1):_c('div',[_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Location","error-messages":[
        _vm.veeErrors.first('live-stream-url'),
        _vm.veeErrors.first('location') ]}},[_c('am2-elegant-tabs',{attrs:{"items":_vm.locationTabs,"tab-key":_vm.selectedLocationKey,"layout":"wide","data-test-id":"location-tabs"},on:{"select":_vm.handleLocationSelect}}),_vm._v(" "),(_vm.selectedLocationKey === 'physical')?_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"u-margin-top-2",attrs:{"value":_vm.scratchEvent ? _vm.scratchEvent.location : "","autocomplete":"event-location","data-vv-name":"location","data-vv-as":"location","placeholder":"Include a venue, place or address","data-test-id":"event-location"},on:{"input":_vm.handleEventLocationInput}}):_vm._e(),_vm._v(" "),(_vm.selectedLocationKey === 'online')?_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|url'),expression:"'required|url'"}],staticClass:"u-margin-top-2",attrs:{"value":_vm.scratchEvent.presentation.liveStreamUrl,"autocomplete":"url","data-vv-name":"live-stream-url","data-vv-as":"live stream URL","placeholder":"Enter live stream URL"},on:{"input":_vm.handleEventLiveStreamUrlInput}}):_vm._e()],1),_vm._v(" "),(_vm.selectedLocationKey === 'physical')?_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":"Event capacity","error-messages":[_vm.veeErrors.first('event-capacity')]}},[_c('ar-input',{directives:[{name:"validate",rawName:"v-validate",value:('integer|isPositiveInt'),expression:"'integer|isPositiveInt'"}],attrs:{"value":_vm.scratchEvent ? _vm.scratchEvent.capacity : "","autocomplete":"event-capacity","data-vv-name":"event-capacity","data-vv-as":"event capacity","placeholder":"Enter event capacity","type":"number","data-test-id":"event-capacity"},on:{"input":_vm.handleEventCapacityInput}})],1):_vm._e()],1),_vm._v(" "),_c('div',{class:[
      'u-display-flex',
      'u-margin-top-10',
      _vm.$arMediaQuery.window.maxWidth('md') && 'u-flex-flow-column',
      'u-justify-content-space-between'
    ]},[_c('ar-field',{attrs:{"label":"Event starts","error-messages":[
        _vm.veeErrors.first('startDate'),
        _vm.veeErrors.first('timezone') ]}},[_c('div',{class:[
          'u-display-flex',
          'u-align-items-center' ]},[_c('am2-date-time-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.startDateValidate),expression:"startDateValidate"}],attrs:{"time-zone":_vm.scratchEvent ? _vm.scratchEvent.timeZone : null,"value":_vm.scratchEvent? _vm.scratchEvent.startDate : null,"size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium',"data-vv-name":"startDate","data-vv-as":"event start time","data-test-id":"event-start-time"},on:{"input":_vm.handleEventStartDateInput}}),_vm._v(" "),(!_vm.displayEndTime && !_vm.$arMediaQuery.window.maxWidth('xs'))?_c('ar-link-button',{staticClass:"u-margin-left-3",attrs:{"text":"Add end time","data-test-id":"add-event-end-time-link"},on:{"click":_vm.handleAddEndTimeClick}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"u-margin-top-2 u-display-flex u-flex-flow-column",style:({ marginBottom: '5px' })},[_c('am2-timezone-dropdown',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-name":"timezone","value":_vm.selectedTimezone,"data-test-id":"event-timezone-link"},on:{"input":_vm.handleEventTimeZoneInput}}),_vm._v(" "),(!_vm.displayEndTime && _vm.$arMediaQuery.window.maxWidth('xs'))?_c('ar-link-button',{staticClass:"u-margin-top-2",attrs:{"text":"Add end time","data-test-id":"add-event-end-time-link"},on:{"click":_vm.handleAddEndTimeClick}}):_vm._e()],1)]),_vm._v(" "),(_vm.$arMediaQuery.window.minWidth('lg') && _vm.displayEndTime)?_c('ar-divider',{style:({
        position: 'relative',
        top: '57px',
        width: 'calc(100% - 652px)',
        margin: '0 18px',
      })}):_vm._e(),_vm._v(" "),(_vm.displayEndTime)?_c('ar-field',{class:[
        'u-display-flex',
        'u-flex-flow-column',
        _vm.$arMediaQuery.window.maxWidth('md') ? 'u-margin-top-10' : null ],attrs:{"label":"Event ends","error-messages":[_vm.veeErrors.first('endDate')]}},[_c('am2-date-time-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.endDateValidate),expression:"endDateValidate"}],attrs:{"time-zone":_vm.scratchEvent ? _vm.scratchEvent.timeZone : null,"value":_vm.scratchEvent ? _vm.scratchEvent.endDate : null,"disabled-until":_vm.scratchEvent.startDate,"size":_vm.$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium',"data-vv-name":"endDate","data-vv-as":"event end time","data-test-id":"event-end-time"},on:{"input":_vm.handleEventEndDateInput}}),_vm._v(" "),_c('ar-link-button',{style:({
          marginTop: '8px',
        }),attrs:{"text":"Remove end time","data-test-id":"remove-event-end-time-link"},on:{"click":_vm.handleRemoveEndTimeClick}})],1):_vm._e()],1),_vm._v(" "),_c('ar-field',{staticClass:"u-margin-top-10",attrs:{"label":_vm.currentEventRSVPForm ? 'Event image' : 'Event image (optional)',"error-messages":[
      _vm.veeErrors.first('eventImage'),
      _vm.veeErrors.first('eventImageUploading')
    ]}},[_c('div',{style:({
        height: (_vm.scratchEventImageUrl || _vm.isUploadingEventImage) ? null : '0',
        overflow: 'hidden',
      })},[(_vm.isUploadingEventImage)?_c('div',{directives:[{name:"validate",rawName:"v-validate:isUploadingEventImage",value:('uploadingAssets'),expression:"'uploadingAssets'",arg:"isUploadingEventImage"}],attrs:{"data-vv-name":"eventImageUploading","data-vv-as":"event image"}}):_vm._e(),_vm._v(" "),(_vm.currentEventRSVPForm)?_c('div',{directives:[{name:"validate",rawName:"v-validate:scratchEventImageUrl",value:('required'),expression:"'required'",arg:"scratchEventImageUrl"}],attrs:{"data-vv-name":"eventImage","data-vv-as":"event image"}}):_vm._e(),_vm._v(" "),_c('am2-dropzone',{ref:"img-dropzone",attrs:{"file-type":['image/jpeg', 'image/jpg', 'image/png'],"file-type-alias":"image","placeholder-icon":{
          name: 'image',
        },"image-options":{
          dimensions: {
            width: 1200,
            height: 628
          }
        },"ratio":0.525,"feedback-type":"image","feedback":_vm.scratchEventImageUrl,"enable-ar-s3-bucket-upload":"","ar-s3-bucket-upload-options-list":_vm.arS3BucketUploadOptionsList},on:{"delete":_vm.handleImageDelete,"uploadStart":function($event){_vm.isUploadingEventImage = true},"uploadEnd":function($event){_vm.isUploadingEventImage = false},"upload":_vm.handleImageUpload}})],1),_vm._v(" "),(!_vm.scratchEventImageUrl && !_vm.isUploadingEventImage)?_c('div',{staticClass:"u-display-flex"},[_c('ar-icon',{staticClass:"u-margin-right-2",attrs:{"name":"image","height":"21px","color":_vm.$arStyle.color.blueGrey600}}),_vm._v(" "),_c('ar-link-button',{attrs:{"text":"Upload image","data-test-id":"upload-image-link"},on:{"click":_vm.handleUploadImageClick}})],1):_vm._e()]),_vm._v(" "),(_vm.isSuggestedAudiencesEnabled)?_c('div',{staticClass:"suggested-audience-section"},[_c('div',{staticClass:"heading"},[_c('ar-icon',{staticClass:"icon",attrs:{"name":"magic","width":"24px","color":_vm.$arStyle.color.purple500}}),_vm._v(" "),_c('div',{staticClass:"heading-text"},[_c('ar-text',{attrs:{"text":"Generate your audience (optional)","size":"16px","weight":"bold"}}),_vm._v(" "),_c('ar-text',{style:({
            color: _vm.$arStyle.color.skyBlueGrey700,
            marginTop: '4px',
          }),attrs:{"text":"Creates an audience based on your selected criteria","size":"14px"}})],1)],1),_vm._v(" "),_c('am2-field',{staticClass:"suggested-audience__item",attrs:{"name":"audience-tag","label":"Audience tagged with"}},[_c('div',{staticClass:"input-search-wrapper tag-input-container"},[_c('div',{class:['input-arrowhead-wrapper', _vm.eventsListOpen ? 'active' : '']},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutsideTagContainer),expression:"handleClickOutsideTagContainer"}],staticClass:"input-selection-container tag-container"},[(_vm.tagOpen)?_c('div',{staticClass:"search-icon-wrapper"},[_c('SearchIcon',{staticClass:"search-icon"})],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tagOpen && _vm.selectedTags.length > 0),expression:"!tagOpen && selectedTags.length > 0"}],staticClass:"tag-wrapper"},_vm._l((_vm.selectedTags),function(tag,index){return _c('am2-tag',{key:("selected-tag-" + index),attrs:{"type":"purple-soft","shape":"rectangle","text":tag.name,"has-cross":"","removeButtonStyle":{
                  backgroundColor: 'transparent',
                }},on:{"remove":function($event){return _vm.handleRemoveAction(tag)}}})}),1),_vm._v(" "),_c('ar-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.tagOpen || _vm.selectedTags.length === 0),expression:"tagOpen || selectedTags.length === 0"}],ref:"tagInput",staticClass:"message-input tag-input",style:({
                paddingLeft: _vm.tagOpen ? '23px' : '0px'
              }),attrs:{"id":_vm.tagInputId,"placeholder":_vm.tagPlaceholder,"value":_vm.tagInputValue},on:{"focus":_vm.handleTagFocus,"input":_vm.handleTagSearchInput}}),_vm._v(" "),_c('div',{staticClass:"link-arrowhead-wrapper",on:{"click":_vm.handleTagArrowClick}},[_c('ArrowHead',{staticClass:"send-to-input-dropdown-arrowhead",class:_vm.tagOpen && 'rotate',attrs:{"height":"8px","width":"10px","fill":_vm.$arStyle.color.skyBlueGrey700}})],1)],1)])]),_vm._v(" "),(_vm.tagOpen)?_c('div',{class:['list-items-wrapper dropdown-list-items-wrapper tags-list', _vm.filteredTags.length > 4 ? 'overflow' : ''],attrs:{"data-test-id":"suggested-audience-tag-list"}},[_vm._l((_vm.filteredTags),function(tag){return _c('div',{key:tag.oid,class:[
            'link-list-item',
            'list-item' ],attrs:{"id":("listItem-" + (tag.oid))},on:{"click":function($event){return _vm.handleTagSelect(tag)}}},[_c('div',{staticClass:"dropdown-copy-wrapper tag-copy-wrapper"},[_c('ar-text',{staticClass:"list-name",attrs:{"multiple-lines":"","text":tag.name,"size":"14px"}}),_vm._v(" "),_c('ar-text',{staticClass:"list-contacts",style:({ color: _vm.$arStyle.color.skyBlueGrey700 }),attrs:{"multiple-lines":"","text":_vm.formatNumber(tag.count),"size":"14px"}})],1)])}),_vm._v(" "),(!_vm.filteredTags || _vm.filteredTags.length === 0)?_c('div',{staticClass:"link-list-item list no-match"},[_c('ar-text',{staticClass:"list-name",style:({ margin: '0 16px' }),attrs:{"size":"xs","text":"No matching tags found","multiple-lines":"","max-lines":1,"line-height":"20px","weight":"normal"}})],1):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('am2-field',{staticClass:"suggested-audience__item",attrs:{"name":"event","label":"Purchased ticket to"}},[_c('div',{staticClass:"input-search-wrapper event-input-container"},[_c('div',{class:['input-arrowhead-wrapper', _vm.tagOpen ? 'active' : '']},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleClickOutsideEventsListContainer),expression:"handleClickOutsideEventsListContainer"}],staticClass:"input-selection-container event-container"},[(_vm.eventsListOpen)?_c('div',{staticClass:"search-icon-wrapper"},[_c('SearchIcon',{staticClass:"search-icon"})],1):_vm._e(),_vm._v(" "),_c('ar-input',{ref:"senderLinkListInput",staticClass:"message-input send-to-input",style:({
                paddingLeft: _vm.eventsListOpen ? '23px' : '0px'
              }),attrs:{"id":_vm.linkInputId,"placeholder":_vm.linkToPlaceholder,"value":_vm.eventInputValue},on:{"focus":_vm.handleLinkFocus,"input":_vm.handleEventSearchInput}}),_vm._v(" "),_c('div',{staticClass:"link-arrowhead-wrapper",on:{"click":_vm.handleLinkArrowClick}},[_c('ArrowHead',{staticClass:"send-to-input-dropdown-arrowhead",class:_vm.eventsListOpen && 'rotate',attrs:{"height":"8px","width":"10px","fill":_vm.$arStyle.color.skyBlueGrey700}})],1)],1)])]),_vm._v(" "),(_vm.eventsListOpen)?_c('div',{class:['list-items-wrapper dropdown-list-items-wrapper events-list', _vm.filteredEventsList.length > 4 ? 'overflow' : ''],attrs:{"data-test-id":"suggested-audience-event-list"}},[_vm._l((_vm.filteredEventsList),function(event){return _c('div',{key:event.oid,class:[
            'link-list-item',
            'list-item' ],attrs:{"id":("listItem-" + (event.oid))},on:{"click":function($event){return _vm.handleEventSelect(event)}}},[_c('ar-checkbox',{ref:("suggested-event-checkbox-" + (event.oid)),refInFor:true,staticClass:"dropdown-item-checkbox",attrs:{"data-test-id":("suggested-event-checkbox-" + (event.oid))},model:{value:(_vm.linkedEventsLocal[event.oid]),callback:function ($$v) {_vm.$set(_vm.linkedEventsLocal, event.oid, $$v)},expression:"linkedEventsLocal[event.oid]"}}),_vm._v(" "),_c('div',{staticClass:"dropdown-copy-wrapper"},[_c('ar-text',{staticClass:"list-name",attrs:{"multiple-lines":"","text":event.name,"weight":"bold","size":"14px"}}),_vm._v(" "),_c('div',{staticClass:"event-date-location-wrapper"},[_c('ar-text',{staticClass:"list-date",attrs:{"multiple-lines":"","text":event['date-string'],"weight":"normal","size":"14px"}}),_vm._v(" "),(event.location)?_c('ar-text',{staticClass:"list-date",attrs:{"multiple-lines":"","text":"•","weight":"normal","size":"14px"}}):_vm._e(),_vm._v(" "),_c('ar-text',{staticClass:"list-location",attrs:{"multiple-lines":"","text":event.location,"weight":"normal","size":"14px"}})],1)],1)],1)}),_vm._v(" "),(!_vm.noMatchingLinkLists)?_c('div',{staticClass:"events-apply-deselect-wrapper"},[_c('div',{staticClass:"deselect-link-wrapper",attrs:{"data-test-id":"deselect-all-events-link"},on:{"click":_vm.handleDeselectAll}},[_c('ar-link-button',{staticClass:"deselect-link",attrs:{"text":"Deselect","has-underline":""}})],1),_vm._v(" "),_c('div',{staticClass:"apply-button-wrapper",attrs:{"data-test-id":"`apply-events-button`"},on:{"click":_vm.handleApplyEventSelections}},[_c('ar-simple-button',{staticClass:"apply-button",style:({
                padding: '10px 16px 11px',
                height: 'unset'
              }),attrs:{"text":"Apply","weight":"bold"}})],1)]):_vm._e(),_vm._v(" "),(_vm.noMatchingLinkLists)?_c('div',{staticClass:"link-list-item list no-match"},[_c('ar-text',{staticClass:"list-name",style:({ margin: '0 16px' }),attrs:{"size":"xs","text":"No matching events found","multiple-lines":"","max-lines":1,"line-height":"20px","weight":"normal"}})],1):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('am2-field',{staticClass:"suggested-audience__item",attrs:{"name":"signup-form","label":"Link a Signup form"}},[_c('SearchMultiSelect',{attrs:{"value":_vm.signupSelected,"items-list":_vm.signupForms,"is-loading":_vm.isFetchingSignupForms,"data-test-id":"suggested-audience-signup-form-list"},on:{"load-more":_vm.loadMoreSignupForms,"select":_vm.handleSignupFormSelect,"search":_vm.handleSignupFormSearch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var item = ref.item;
return [_c('ar-text',{staticClass:"list-name",attrs:{"multiple-lines":"","text":item.name,"size":"14px"}})]}}],null,false,2089547506)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }