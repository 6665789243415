<template>
  <div
    :class="[
      'message-list-advanced-targeting-section',
    ]"

  >
  <div class="block-container  u-margin-top-2">
    <div :style="{
      width: '50%',
      display: 'flex',
      verticalAlign: 'middle'
    }">
      <ar-checkbox v-if="channel === 'email'"
        class="u-display-flex u-justify-content-space-between"
        @change="handleIgnoreSuppression"
        v-model="ignoreSuppressed"
        label="Ignore suppressions"
        data-test-id="ignore-suppressions-toggle" />
      <ar-icon v-if="channel === 'email'"
        name="tooltip-question-mark"
        class="u-margin-left-2"
        :color="$arStyle.color.skyBlueGrey600"
        v-tooltip.top="{
            content: 'Emails are placed on a suppression list when they have bounced 2 or more times. Enabling “Ignore suppressions” overrides the suppression list and allows you to send emails to fans on the suppression list.',
          }"
      />
    </div>
    <div v-if="showToggle"
        class="toggle-module">
      <ar-link-button
        v-if="!toggle"
        :text="toggleText"
        has-underline
        @click="handleToggle"
        data-test-id="message-list-targeting-toggle"
      />
  </div>
  </div>

    <div v-if="showToggle && toggle"
         class="targeting-module u-margin-top-8">
      <div class="u-display-flex u-justify-content-space-between u-margin-top-8">
         <ar-text
         text="Quick filters"
         size="xs"
         weight="bold"
         />

      <ar-link-button
        :text="toggleText"
        has-underline
        @click="handleToggle"
        data-test-id="message-list-targeting-toggle"
      />

      </div>
      <ar-field
        :style="{}">
        <ar-simple-select
          class="select-filter-type u-margin-top-2"
          :items="filterTypeItems"
          :default-select-index="selectedTypeIndex"
          data-test-id="message-targeting-type-select"
          @select="handleTypeSelect" />
      </ar-field>

      <!-- ADVANCED FILTERING BY TAG -->
      <div
        v-if="displayedSubsection === 'tag'"
        :class="['subsection',
               'tag-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          :style="{
            minWidth: '184px'
          }">
          <ar-text
            class="label"
            size="14px"
            text="Send only to people"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            class="select-filter-type"
            :items="tagSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            data-test-id="message-targeting-condition-select"
            @select="handleConditionSelect" />
        </ar-field>

        <ar-field
          class="field-value u-width-100-percent">
          <ar-text
            class="label"
            size="14px"
            text="Tag"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <am2-fan-tag-input
            class="tag-input"
            placeholder="Select tag..."
            v-model="selectedTags"
            :allow-custom-input="false"
            :show-tag-count="false"
            :style="{
              backgroundColor:' #FFF',
              paddingTop: '5px',
              paddingBottom: '6px'
            }"
            @input="handleTagInput"
            data-test-id="message-targeting-tag-input"
          />
          <am2-link-button-dropdown
            :items="tagSubdropdownItems"
            :item-key="selectedSubConditionKey"
            :style="{
              marginTop: '8px',
              float: 'right',
            }"
            :dropdown-style="{

            }"
            @select="handleTagSubdropdownItemSelect"
            data-test-id="message-targeting-tag-subcondition-select"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY SEGMENT -->
      <div
        v-if="displayedSubsection === 'segment'"
        :class="['subsection',
               'segment-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          :style="{
            minWidth: '184px'
          }">
          <ar-text
            class="label"
            size="14px"
            text="Send only to people"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            class="select-filter-type"
            :items="segmentSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          class="field-value u-width-100-percent">
          <ar-text
            class="label"
            size="14px"
            text="Segment"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            :items="savedSegmentList"
            class="u-margin-top-3 segment-select u-width-100-percent"
            :default-select-index="selectedSegmentIndex"
            @select="handleSegmentSelect"
            enable-filter
            placeholder="Select segment..."
            data-test-id="message-targeting-segment-select"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY OPENED/NOT OPENED MESSAGES -->
      <div
        v-if="displayedSubsection === 'messages_opened'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          :style="{
            minWidth: '184px'
          }">
          <ar-text
            class="label"
            size="14px"
            text="Send only to people"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            class="select-filter-type"
            :items="openedSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          class="field-value u-width-100-percent">
          <ar-text
            class="label"
            size="14px"
            text="Message"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <am2-message-multiselect
            @select="handleSelect"
            :defaultValue="messageOid"
            data-test-id="message-targeting-message-select-opened"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY CLICKED/NOT CLICKED MESSAGES -->
      <div
        v-if="displayedSubsection === 'messages_clicked'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          :style="{
            minWidth: '184px'
          }">
          <ar-text
            class="label"
            size="14px"
            text="Send only to people"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            class="select-filter-type"
            :items="clickedSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          class="field-value u-width-100-percent">
          <ar-text
            class="label"
            size="14px"
            text="Message"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <am2-message-multiselect
            @select="handleSelect"
            :defaultValue="messageOid"
            data-test-id="message-targeting-message-select-clicked"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY PURCHASED TICKET TO EVENT / DIDN'T PURCHASE TICKET TO EVENT -->
      <div
        v-if="displayedSubsection === 'events'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          :style="{
            minWidth: '184px'
          }">
          <ar-text
            class="label"
            size="14px"
            text="Send only to people"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            class="select-filter-type"
            :items="eventSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          class="field-value u-width-100-percent">
          <ar-text
            class="label"
            size="14px"
            text="Event"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <am2-event-multiselect
            @select="handleSelect"
            :defaultValue="eventOid"
            data-test-id="message-targeting-event-select"
          />
        </ar-field>
      </div>

      <!-- ADVANCED FILTERING BY REGISTERED TO CAMPAIGN / DIDN'T REGISTER TO CAMPAIGN -->
      <div
        v-if="displayedSubsection === 'campaigns'"
        :class="['subsection',
               'message-subsection',
               $arMediaQuery.pageContent.minWidth('md') && 'md-min',
               $arMediaQuery.pageContent.maxWidth('sm') && 'sm-max']"
      >
        <ar-field
          class="field-condition"
          :style="{
            minWidth: '184px'
          }">
          <ar-text
            class="label"
            size="14px"
            text="Send only to people"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <ar-simple-select
            class="select-filter-type"
            :items="campaignSelectConditionItems"
            :default-select-index="selectedConditionIndex"
            @select="handleConditionSelect"
            data-test-id="message-targeting-condition-select" />
        </ar-field>

        <ar-field
          class="field-value u-width-100-percent">
          <ar-text
            class="label"
            size="14px"
            text="Campaign"
            weight="bold"
            multiple-lines
            :max-lines="1"
            :style="{
              marginBottom: '8px',
              color: $arStyle.color.skyBlueGrey700,
            }"
          />
          <am2-campaign-multiselect
            @select="handleSelect"
            :defaultValue="campaignOid"
            data-test-id="message-targeting-campaign-select"
          />
        </ar-field>
      </div>



    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapGetters, mapState } from 'vuex';
  import { clone } from '@/utils/helpers/';
  import { recipientFilterToTargetingRules } from '~/store/modules/message/utils';
  import ignoreEmailSendLimit from "~/mixins/ignoreEmailSendLimit";

  export default {
    name: 'MessageListAdvancedTargetingSection',
    mixins: [
      ignoreEmailSendLimit
    ],
    props: {
      channel: {
        type: String,
        required: true,
        validator: (val) => ['email', 'sms', 'facebookMessenger'].indexOf(val) > -1,
      },
      showToggle: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        selectedTags: [],
        filterGroupOid: null,
        messageOid: null,
        eventOid: null,
        campaignOid: null,
        messageSearchString: null,
        eventSearchString: null,
        campaignSearchString: null,
        toggle: null,
        messages: null,
        events: null,
        campaigns: null,
        ignoreSuppressed: false,
      }
    },

    watch: {
      currentMessageList(val, oldVal) {
        if (oldVal !== val) this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({ channel: this.channel });
      },
      ignoreEmailSendLimit(){
        this.handleEmailSendLimit()
      },
    },

    computed: {
      ...mapState({
        promoterOid: state => state.auth.account.promoterOid,
        scratchEmailMessage: state => state.message.scratchEmailMessage,
        scratchSimpleMessage: state => state.message.scratchSimpleMessage,
        currentSelectedMessage: state => state.message.currentSelectedMessage,
        tagState: state => state.tag,
        savedSegmentList: state => state.segment.savedSegmentList,
        advancedMessageListTargeting: state => state.messageList.advancedMessageListTargeting,
        currentMessageList: state => state.messageList.currentMessageList,
      }),
      ...mapGetters({
        currentAdvancedTargetingIsValid: 'messageList/currentAdvancedTargetingIsValid',
      }),

      toggleText() {
        if (this.toggle) return "Hide quick filters";
        return "Show quick filters";
      },

      selectedType() {
        return this.advancedMessageListTargeting.type || 'all';
      },

      selectedSubConditionKey() {
        return this.advancedMessageListTargeting.subCondition;
      },

      selectedTypeIndex() {
        return this.filterTypeItems.findIndex( item => {
          return item.key === this.selectedType
        })
      },

      selectedConditionIndex() {
        if (this.advancedMessageListTargeting.condition === 'include') return 0;
        return 1;
      },

      // Keeping 'segment' and 'recent_send' commented out for now, as adding them will
      // require additional work
      filterTypeItems() {
        return [
          {
            name: 'Select...',
            key: 'all',
          },
          {
            name: 'Tag',
            key: 'tag',
          },
          {
            name: 'Opened message / Didn\'t open message',
            key: 'messages_opened',
          },
          {
            name: 'Clicked message / Didn\'t click message',
            key: 'messages_clicked',
          },
          {
            name: 'Purchased ticket to / Didn\'t purchase ticket to',
            key: 'events',
          },
          {
            name: 'Registered to campaign / Didn\'t register to campaign',
            key: 'campaigns',
          },

          // {
          //   name: 'Segment',
          //   key: 'segment',
          // },
          // {
          //   name: 'Exclude based on number of recent messages',
          //   key: 'recent_send',
          // },
        ];
      },

      displayedSubsection() {
        switch(this.selectedTypeIndex) {
          case 1:
            return "tag";
          case 2:
            return "messages_opened";
          case 3:
            return "messages_clicked";
          case 4:
            return "events";
          case 5:
            return "campaigns";
          case 0:
          default:
            return false;
        }
      },

      tagSelectConditionItems() {
        return [
          {
            name: 'With tag',
            key: 'include',
          },
          {
            name: 'Without tag',
            key: 'exclude',
          },
        ]
      },

      segmentSelectConditionItems() {
        return [
          {
            name: 'In segment',
            key: 'include',
          },
          {
            name: 'Not in segment',
            key: 'exclude',
          },
        ]
      },

      openedSelectConditionItems() {
        return [
          {
            name: 'Opened message',
            key: 'include',
          },
          {
            name: 'Didn\'t open message',
            key: 'exclude',
          },
        ]
      },

      clickedSelectConditionItems() {
        return [
          {
            name: 'Clicked message',
            key: 'include',
          },
          {
            name: 'Didn\'t click message',
            key: 'exclude',
          },
        ]
      },

      eventSelectConditionItems() {
        return [
          {
            name: 'Purchased to',
            key: 'include',
          },
          {
            name: 'Didn\'t purchase',
            key: 'exclude',
          },
        ]
      },

      campaignSelectConditionItems() {
        return [
          {
            name: 'Registered to',
            key: 'include',
          },
          {
            name: 'Didn\'t register',
            key: 'exclude',
          },
        ]
      },

      tagSubdropdownItems() {
        if (this.selectedConditionIndex === 0) {
          return [
            {
              name: "Any of the tags",
              key: "true_to_any"
            },
            {
              name: "All of the tags",
              key: "true_to_all"
            },
          ];
        }

        return [
          {
            name: "Without any of the tags",
            key: "false_to_any"
          },
          {
            name: "Without all of the tags",
            key: "false_to_all"
          },
        ];
      },

      selectedSegmentIndex() {
        return this.savedSegmentList.findIndex(({ value }) => {
          return value === this.filterGroupOid;
        });
      },
    },

    mounted() {
      this.initializeValues();
    },

    methods: {
      ...mapActions([
        'segment/FETCH_SEGMENTS',
        'message/FETCH_MORE_MESSAGES',
        'messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT',
      ]),
      ...mapMutations([
        'tag/RESET_TAGS',
        'messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING',
        'messageList/SET_FILTERED_RECIPIENT_LIST_COUNT',
        'messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING',
        'message/PATCH_SCRATCH_EMAIL_MESSAGE',
      ]),

      async initializeValues() {
        let targetingVals = null;

        if (this.currentSelectedMessage?.meta?.recipientFilter &&
           ((!this.scratchEmailMessage?.meta?.recipientFilter || !(this.scratchEmailMessage?.meta?.recipientFilter?.conditions?.length > 0)) && !this.scratchEmailMessage?.oid) &&
           (!this.scratchSimpleMessage?.meta?.recipientFilter && !this.scratchSimpleMessage?.oid)
        ) {
          targetingVals = recipientFilterToTargetingRules(this.currentSelectedMessage.meta.recipientFilter);
        } else {
          if (this.channel === 'email') {
            targetingVals = recipientFilterToTargetingRules(this.scratchEmailMessage?.meta?.recipientFilter || null);
          } else {
            targetingVals = recipientFilterToTargetingRules(this.scratchSimpleMessage?.meta?.recipientFilter || null);
          }
        }

        if (targetingVals.values && targetingVals.values.length > 0) {
          if (['messages_opened', 'messages_clicked'].indexOf(targetingVals.type) > -1) {
            this.messageOid = targetingVals.values
          }
          if (targetingVals.type === 'events') {
            this.eventOid = targetingVals.values
          }
          if (targetingVals.type === 'campaigns') {
            this.campaignOid = targetingVals.values
          }
        }

        if (!!targetingVals) {
          this.toggle = !(targetingVals.type == "all")
          this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](targetingVals);
          this.fetchTagsFromOids();
        }

        this.ignoreSuppressed = this.scratchEmailMessage?.meta?.ignoreSuppressed || false;
        this.ignoreEmailSendLimit = this.scratchEmailMessage?.meta?.ignoreEmailSendLimit || false;

        // Commenting this out - TODO - lets remove it if this doesn't cause issues
        //this['messageList/FETCH_FILTERED_RECIPIENT_LIST_COUNT']({ channel: this.channel });
      },

      resetValues() {
        this['messageList/RESET_ADVANCED_MESSAGE_LIST_TARGETING']();
        this.selectedTags = [];
        this.filterGroupOid = null;
        this.messageOid = [];
        this.campaignOid = [];
        this.eventOid = [];
        this.messageSearchString = null;
        this.eventSearchString = null;
        this.campaignSearchString = null;
        this.$emit('updateAdvancedTargeting');
      },

      async fetchTagsFromOids() {
        if (this.advancedMessageListTargeting.type !== 'tag') return;
        if (this.advancedMessageListTargeting.values.length === 0) return;
        let url = `/promoter/${this.promoterOid}/tag?$count=true&$orderby=count%20desc&$filter=oid=`;
        url = `${url}${this.advancedMessageListTargeting.values.join(' OR oid=')}`;
        const { data: { count, rows } } = await this.$axios.get(url);
        const tags = rows || [];
        this.selectedTags = tags.map( item => item.name);
      },

      handleTypeSelect(name, idx) {
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.type = name?.key;
        newAdvancedMessageListTargeting.condition = 'include';
        newAdvancedMessageListTargeting.subCondition =  name?.key === 'tag' ? 'true_to_all' : null;
        newAdvancedMessageListTargeting.values = [];
        this.selectedTags = [];
        this.filterGroupOid = null;
        this.messageSearchString = null;
        this.eventOid = [];
        this.messageOid = [];
        this.campaignOid = [];
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleConditionSelect(name, idx) {
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.condition = name?.key;
        if (newAdvancedMessageListTargeting.type === 'tag') {
          const currentSubCondition = newAdvancedMessageListTargeting.subCondition;
          if (name?.key === 'include' && currentSubCondition === 'false_to_all') {
            newAdvancedMessageListTargeting.subCondition = 'true_to_all'
          } else if (name?.key === 'include' && currentSubCondition === 'false_to_any') {
            newAdvancedMessageListTargeting.subCondition = 'true_to_any'
          } else if (name?.key === 'exclude' && currentSubCondition === 'true_to_all') {
            newAdvancedMessageListTargeting.subCondition = 'false_to_all'
          } else if (name?.key === 'exclude' && currentSubCondition === 'true_to_any') {
            newAdvancedMessageListTargeting.subCondition = 'false_to_any'
          }
        }
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleEmailSendLimit(){
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            ignoreEmailSendLimit: this.ignoreEmailSendLimit
          },
        });
      },

      handleSegmentSelect(item) {
        this.filterGroupOid = item.value;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = [item.value];
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleSelect(item) {
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = item;
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      // Items comes back as a string of names, whereas we need to pass the server a set of OIDs
      // So we'll need to extract the OID values from the vuex state, using the names we have available
      async handleTagInput(items) {
        this.selectedTags = items;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.values = await this.fetchTagOids(items);
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },


      async fetchTagOids(tagNames) {
        const filterString = `name = "${tagNames.join('" OR name = "')}"`;

        const res = await this.$axios.get(`/promoter/${this.promoterOid}/tag`, {
          params: {
            $count: true,
            $select: 'oid,name',
            $orderby: 'favourite desc',
            $top: 'all',
            $skip: 0,
            $filter: filterString,
          },
        });

        const rows = res?.data?.rows || [];
        return rows.map(item => item.oid);
      },

      handleTagSubdropdownItemSelect(item) {
        const tagSubdropdownKey = item.key;
        const newAdvancedMessageListTargeting = clone(this.advancedMessageListTargeting);
        newAdvancedMessageListTargeting.subCondition = tagSubdropdownKey;
        this['messageList/SET_ADVANCED_MESSAGE_LIST_TARGETING'](newAdvancedMessageListTargeting);
        this.$emit('updateAdvancedTargeting');
      },

      handleIgnoreSuppression(){
        this['message/PATCH_SCRATCH_EMAIL_MESSAGE']({
          meta: {
            ignoreSuppressed: this.ignoreSuppressed
          },
        });
      },

      handleToggle() {
        this.toggle = !this.toggle;
      },

      handleOpenedMessageFilterStringInput(val) {
        this.messageSearchString = val || null;
      },

      handleClickedMessageFilterStringInput(val) {
        this.messageSearchString = val || null;
      },

      handleEventFilterStringInput(val) {
        this.eventSearchString = val || null;
      },

      handleCampaignFilterStringInput(val) {
        this.campaignSearchString = val || null;
      },
    }

  };
</script>

<style lang="scss" scoped>
 .message-list-advanced-targeting-section {

   .toggle-module {
     display: flex;
     width: 50%;
     justify-content: flex-end;
   }

   .targeting-module {
     .subsection {
       margin-top: 44px;

       &.md-min {
         display: flex;
         flex-direction: row;

         .field-value {
           margin-left: 20px;
           max-width: calc(100% - 204px);
         }
       }

       &.sm-max {
         .field-value {
           margin-top: 20px;
         }
       }

      &.tag-subsection {
        &.sm-max {
          margin-bottom: 40px;
        }
      }
     }

   }

   .block-container {
    display: flex;
    vertical-align: middle;
   }
 }
</style>
