<template>
  <portal
    to="modal"
    :disabled="!isShow"
    :style="{ position: 'absolute' }"
  >
    <ar-modal
      :is-open="isShow"
      hide-header
      width="590px"
      :mobile="$arMediaQuery.window.maxWidth('xs')"
      @close="()=>$emit('revert')"
    >
      <div
        slot="body"
        class="email-send-limit-modal"
      >
        <div class="exclamation-mark">
          <ar-icon
            name="alert-exclamation-mark"
            height="70px"
          />
        </div>
        <section
          class="text-content u-margin-top-4"
        >
          <ar-text
            text="Are you sure you want to disable email send limit?"
            allow-html
            multiple-lines
            align="center"
            size="sm"
          />
        </section>
        <div class="buttons-row u-margin-top-10">
          <ar-simple-button
            text="No, keep active"
            outlined
            class="u-margin-right-2"
            @click="()=>$emit('revert')"
            data-test-id="`email-send-limit-modal-cancel`"
          />
          <ar-simple-button
            text="Yes, disable send limit"
            weight="bold"
            :style="{
                  padding: '10px 16px 11px',
                  height: 'unset'
                }"
            @click="()=>$emit('confirm')"
            data-test-id="`email-send-limit-modal-confirm`"
          />
        </div>
      </div>
    </ar-modal>
  </portal>
</template>

<script>
export default {
  name: 'EmailSendLimitModal',
  components: {},
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

  .email-send-limit-modal {
    padding: 60px 48px 40px 48px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ::v-deep .exclamation-mark {
      display: flex;
      justify-content: center;
    }

    ::v-deep .buttons-row {
      display: flex;
      justify-content: center;
    }

  }
</style>
