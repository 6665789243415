<template>
  <div :class="[
    'settings-view',
    $arMediaQuery.pageContent.maxWidth('md') && 'md-max',
  ]">
    <div class="title-section">
      <ar-text
        size="lg"
        text="Account Settings"
      />
    </div>
    <am2-tabs
      :selected-tab-index="selectedPageItemIdx"
      :items="filteredPageItems"
      :style="{
        marginTop: '22px',
      }"
      @select="handlePageItemSelect"
    />
    <ar-divider />
    <nuxt-child
      class="child-content"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {defaultSettingsPath} from "@/utils/helpers";
import {isPromoterWhitelisted} from "~/store/modules/integration/utils";

export default {
  layout: 'default',

  computed: {
    ...mapGetters({
      isFeatureEnabled: 'auth/isFeatureEnabled',
      isPermissionEnabled: 'auth/isPermissionEnabled',
    }),
    ...mapState({
      path: state => state.route.path,
      promoterOid: state => state.auth.account.promoterOid,
      emailAddress: state => state.auth.account.emailAddress,
      promoter: state => state.auth.account,
    }),
    isApiTokensFeatureEnabled() {
      return this.isFeatureEnabled(['api-tokens']);
    },
    isUserManagementPermitted() {
      return this.isPermissionEnabled(['user-management'])
    },
    filteredPageItems() {
      // Add ability to remove options from the settings section based on certain promoter account conditions
      const defaultPathValue = defaultSettingsPath(this.promoterOid);
      return this.pageItems.filter(item => {
        if (item.title === 'API Tokens') {
          return this.isApiTokensFeatureEnabled
        } else if (item.title === 'Users') {
          return this.isUserManagementPermitted
        } else if (item.title === 'Subscription') {
          return defaultPathValue === '/settings/subscriptions'
        } else {
          return true;
        }
      })
    },
    selectedPageItemIdx() {
      return this.filteredPageItems.findIndex(item => item.path === this.path);
    },
    pageItems() {
      let defaultItems = [
        //{
        //  title: 'Subscription',
        //  path: '/settings/subscriptions'
        //},
        // {
        //   title: 'Profile',
        //   path: '/settings/profile'
        // },
        {
          title: 'Password',
          path: '/settings/password'
        },
        {
          title: 'MFA',
          path: '/settings/mfa'
        },
        {
          title: 'Billing',
          path: '/settings/billing'
        },
        {
          title: 'Integrations',
          path: '/settings/integrations'
        },
        {
          title: 'API Tokens',
          path: '/settings/api-tokens'
        },
        {
          title: 'Region',
          path: '/settings/region'
        },
        {
          title: 'Users',
          path: '/settings/users'
        },
        {
          title: 'Domains',
          path: '/settings/domains'
        },
      ]

      if(isPromoterWhitelisted(this.promoter)){
        defaultItems.push({
          title: 'Email Send Limit',
            path: '/settings/email-send-limit'
        })
      }

      return defaultItems
    }
  },

  async mounted() {
    this['auth/FETCH_PERMISSIONS']()
  },

  methods: {
    ...mapActions(['auth/FETCH_PERMISSIONS']),

    handlePageItemSelect(tab) {
      this.$router.push(tab.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.settings-view {
  max-width: 1200px;
  padding: 50px 30px 30px;
  margin: 0 auto;

  &.md-max {
    padding: 50px 12px 12px;
  }

  .title-section {
    height: 50px;
  }
}
</style>
