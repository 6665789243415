<template>
  <section class="event-details">
    <ar-field
      label="Event name"
      :error-messages="[veeErrors.first('event-name')]"
    >
      <ar-input
        v-validate="'required|max:150'"
        :value="scratchEvent ? scratchEvent.name : ``"
        @input="handleEventNameInput"
        autocomplete="event-name"
        data-vv-name="event-name"
        data-vv-as="event name"
        placeholder="Event name"
        type="text"
        data-test-id="event-name"
      />
    </ar-field>
    <!-- If it's a Zoom Event, don't display location tabs -->
    <ar-field
      v-if="scratchEventIsZoomEvent"
      label="Location"
      class="u-margin-top-10"
    >
      <am2-new-slug-input
        :value="zoomUrlFromCurrentEvent"
        readonly
        enable-copy
      />
    </ar-field>
    <div
      v-else
    >
      <ar-field
        label="Location"
        class="u-margin-top-10"
        :error-messages="[
          veeErrors.first('live-stream-url'),
          veeErrors.first('location'),
        ]"
      >
        <am2-elegant-tabs
          :items="locationTabs"
          :tab-key="selectedLocationKey"
          @select="handleLocationSelect"
          layout="wide"
          data-test-id="location-tabs"
        />
        <ar-input
          v-if="selectedLocationKey === 'physical'"
          class="u-margin-top-2"
          :value="scratchEvent ? scratchEvent.location : ``"
          @input="handleEventLocationInput"
          autocomplete="event-location"
          v-validate="'required'"
          data-vv-name="location"
          data-vv-as="location"
          placeholder="Include a venue, place or address"
          data-test-id="event-location"
        />
        <ar-input
          v-if="selectedLocationKey === 'online'"
          class="u-margin-top-2"
          v-validate="'required|url'"
          :value="scratchEvent.presentation.liveStreamUrl"
          @input="handleEventLiveStreamUrlInput"
          autocomplete="url"
          data-vv-name="live-stream-url"
          data-vv-as="live stream URL"
          placeholder="Enter live stream URL"
        />
      </ar-field>
      <ar-field
        v-if="selectedLocationKey === 'physical'"
        label="Event capacity"
        :error-messages="[veeErrors.first('event-capacity')]"
        class="u-margin-top-10"
      >
        <ar-input
          v-validate="'integer|isPositiveInt'"
          :value="scratchEvent ? scratchEvent.capacity : ``"
          @input="handleEventCapacityInput"
          autocomplete="event-capacity"
          data-vv-name="event-capacity"
          data-vv-as="event capacity"
          placeholder="Enter event capacity"
          type="number"
          data-test-id="event-capacity"
        />
      </ar-field>
    </div>
    <div
      :class="[
        'u-display-flex',
        'u-margin-top-10',
        $arMediaQuery.window.maxWidth('md') && 'u-flex-flow-column',
        'u-justify-content-space-between'
      ]"
    >
      <ar-field
        label="Event starts"
        :error-messages="[
          veeErrors.first('startDate'),
          veeErrors.first('timezone'),
        ]"
      >
        <div
          :class="[
            'u-display-flex',
            'u-align-items-center',
          ]"
        >
          <am2-date-time-input
            v-validate="startDateValidate"
            :time-zone="scratchEvent ? scratchEvent.timeZone : null"
            :value="scratchEvent? scratchEvent.startDate : null"
            @input="handleEventStartDateInput"
            :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
            data-vv-name="startDate"
            data-vv-as="event start time"
            data-test-id="event-start-time"
          />
          <ar-link-button
            v-if="!displayEndTime && !$arMediaQuery.window.maxWidth('xs')"
            class="u-margin-left-3"
            text="Add end time"
            @click="handleAddEndTimeClick"
            data-test-id="add-event-end-time-link"
          />
        </div>
        <div class="u-margin-top-2 u-display-flex u-flex-flow-column" :style="{ marginBottom: '5px' }">
          <am2-timezone-dropdown
            v-validate="'required'"
            data-vv-name="timezone"
            :value="selectedTimezone"
            @input="handleEventTimeZoneInput"
            data-test-id="event-timezone-link"
          />
          <ar-link-button
            v-if="!displayEndTime && $arMediaQuery.window.maxWidth('xs')"
            class="u-margin-top-2"
            text="Add end time"
            @click="handleAddEndTimeClick"
            data-test-id="add-event-end-time-link"
          />
        </div>
      </ar-field>
      <!-- Divider -->
      <ar-divider
        v-if="$arMediaQuery.window.minWidth('lg') && displayEndTime"
        :style="{
          position: 'relative',
          top: '57px',
          width: 'calc(100% - 652px)',
          margin: '0 18px',
        }"
      />
      <ar-field
        v-if="displayEndTime"
        label="Event ends"
        :error-messages="[veeErrors.first('endDate')]"
        :class="[
          'u-display-flex',
          'u-flex-flow-column',
          $arMediaQuery.window.maxWidth('md') ? 'u-margin-top-10' : null,
        ]"
      >
        <am2-date-time-input
          v-validate="endDateValidate"
          :time-zone="scratchEvent ? scratchEvent.timeZone : null"
          :value="scratchEvent ? scratchEvent.endDate : null"
          :disabled-until="scratchEvent.startDate"
          @input="handleEventEndDateInput"
          :size="$arMediaQuery.window.maxWidth('xs') ? 'small' : 'medium'"
          data-vv-name="endDate"
          data-vv-as="event end time"
          data-test-id="event-end-time"
        />
        <ar-link-button
          text="Remove end time"
          @click="handleRemoveEndTimeClick"
          :style="{
            marginTop: '8px',
          }"
          data-test-id="remove-event-end-time-link"
        />
      </ar-field>
    </div>
    <ar-field
      :label="currentEventRSVPForm ? 'Event image' : 'Event image (optional)'"
      class="u-margin-top-10"
      :error-messages="[
        veeErrors.first('eventImage'),
        veeErrors.first('eventImageUploading')
      ]"
    >
      <div
        :style="{
          height: (scratchEventImageUrl || isUploadingEventImage) ? null : '0',
          overflow: 'hidden',
        }"
      >
        <div
          v-if="isUploadingEventImage"
          v-validate:isUploadingEventImage="'uploadingAssets'"
          data-vv-name="eventImageUploading"
          data-vv-as="event image"
        />
        <!-- Event image is necessary if it has RSVP Event -->
        <div
          v-if="currentEventRSVPForm"
          v-validate:scratchEventImageUrl="'required'"
          data-vv-name="eventImage"
          data-vv-as="event image"
        />
        <am2-dropzone
          ref="img-dropzone"
          :file-type="['image/jpeg', 'image/jpg', 'image/png']"
          file-type-alias="image"
          :placeholder-icon="{
            name: 'image',
          }"
          :image-options="{
            dimensions: {
              width: 1200,
              height: 628
            }
          }"
          :ratio="0.525"
          feedback-type="image"
          :feedback="scratchEventImageUrl"
          enable-ar-s3-bucket-upload
          :ar-s3-bucket-upload-options-list="arS3BucketUploadOptionsList"
          @delete="handleImageDelete"
          @uploadStart="isUploadingEventImage = true"
          @uploadEnd="isUploadingEventImage = false"
          @upload="handleImageUpload"
        />
      </div>
      <div
        v-if="!scratchEventImageUrl && !isUploadingEventImage"
        class="u-display-flex"
      >
        <ar-icon
          class="u-margin-right-2"
          name="image"
          height="21px"
          :color="$arStyle.color.blueGrey600"
        />
        <ar-link-button
          text="Upload image"
          @click="handleUploadImageClick"
          data-test-id="upload-image-link"
        />
      </div>
    </ar-field>

    <div
      v-if="isSuggestedAudiencesEnabled"
      class="suggested-audience-section"
    >
      <div class="heading">
        <ar-icon
          class="icon"
          name="magic"
          width="24px"
          :color="$arStyle.color.purple500"
        />

        <div class="heading-text">
          <ar-text
            text="Generate your audience (optional)"
            size="16px"
            weight="bold"
          />

          <ar-text
            text="Creates an audience based on your selected criteria"
            size="14px"
            :style="{
              color: $arStyle.color.skyBlueGrey700,
              marginTop: '4px',
            }"
          />
        </div>
      </div>

      <am2-field
        name="audience-tag"
        label="Audience tagged with"
        class="suggested-audience__item"
      >
        <div class="input-search-wrapper tag-input-container">
          <div :class="['input-arrowhead-wrapper', eventsListOpen ? 'active' : '']">
            <div
              class="input-selection-container tag-container"
              v-click-outside="handleClickOutsideTagContainer"
            >
              <div v-if="tagOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
              <div
                v-show="!tagOpen && selectedTags.length > 0"
                class="tag-wrapper"
              >
                <am2-tag
                  v-for="(tag, index) in selectedTags"
                  :key="`selected-tag-${index}`"
                  type="purple-soft"
                  shape="rectangle"
                  :text="tag.name"
                  has-cross
                  @remove="handleRemoveAction(tag)"
                  :removeButtonStyle="{
                    backgroundColor: 'transparent',
                  }"
                />
              </div>
              <ar-input
                v-show="tagOpen || selectedTags.length === 0"
                :id="tagInputId"
                ref="tagInput"
                class="message-input tag-input"
                :style="{
                  paddingLeft: tagOpen ? '23px' : '0px'
                }"
                :placeholder="tagPlaceholder"
                @focus="handleTagFocus"
                @input="handleTagSearchInput"
                :value="tagInputValue"
              />
              <div class="link-arrowhead-wrapper" @click="handleTagArrowClick">
                <ArrowHead
                  class="send-to-input-dropdown-arrowhead"
                  :class="tagOpen && 'rotate'"
                  height="8px"
                  width="10px"
                  :fill="$arStyle.color.skyBlueGrey700"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="tagOpen"
          :class="['list-items-wrapper dropdown-list-items-wrapper tags-list', filteredTags.length > 4 ? 'overflow' : '']"
          data-test-id="suggested-audience-tag-list"
        >
          <div
            v-for="tag in filteredTags"
            :id="`listItem-${tag.oid}`"
            :key="tag.oid"
            :class="[
              'link-list-item',
              'list-item',
            ]"
            @click="handleTagSelect(tag)"
          >
            <div class="dropdown-copy-wrapper tag-copy-wrapper">
              <ar-text
                class="list-name"
                multiple-lines
                :text="tag.name"
                size="14px"
              />
              <ar-text class="list-contacts" multiple-lines :text="formatNumber(tag.count)" size="14px" :style="{ color: $arStyle.color.skyBlueGrey700 }" />
            </div>
          </div>
          <div v-if="!filteredTags || filteredTags.length === 0" class="link-list-item list no-match">
            <ar-text
              class="list-name"
              :style="{ margin: '0 16px' }"
              size="xs"
              text="No matching tags found"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              weight="normal"
            />
          </div>
        </div>
      </am2-field>

      <am2-field
        name="event"
        label="Purchased ticket to"
        class="suggested-audience__item"
      >
        <div class="input-search-wrapper event-input-container">
          <div :class="['input-arrowhead-wrapper', tagOpen ? 'active' : '']">
            <div
              class="input-selection-container event-container"
              v-click-outside="handleClickOutsideEventsListContainer"
            >
              <div v-if="eventsListOpen" class="search-icon-wrapper"><SearchIcon class="search-icon" /></div>
              <ar-input
                :id="linkInputId"
                ref="senderLinkListInput"
                class="message-input send-to-input"
                :style="{
                  paddingLeft: eventsListOpen ? '23px' : '0px'
                }"
                :placeholder="linkToPlaceholder"
                @focus="handleLinkFocus"
                @input="handleEventSearchInput"
                :value="eventInputValue"
              />
              <div class="link-arrowhead-wrapper" @click="handleLinkArrowClick">
                <ArrowHead
                  class="send-to-input-dropdown-arrowhead"
                  :class="eventsListOpen && 'rotate'"
                  height="8px"
                  width="10px"
                  :fill="$arStyle.color.skyBlueGrey700"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="eventsListOpen"
          :class="['list-items-wrapper dropdown-list-items-wrapper events-list', filteredEventsList.length > 4 ? 'overflow' : '']"
          data-test-id="suggested-audience-event-list"
        >
          <div
            v-for="event in filteredEventsList"
            :id="`listItem-${event.oid}`"
            :key="event.oid"
            :class="[
              'link-list-item',
              'list-item',
            ]"
            @click="handleEventSelect(event)"
          >
            <ar-checkbox
              :ref="`suggested-event-checkbox-${event.oid}`"
              v-model="linkedEventsLocal[event.oid]"
              class="dropdown-item-checkbox"
              :data-test-id="`suggested-event-checkbox-${event.oid}`"
            />
            <div class="dropdown-copy-wrapper">
              <ar-text
                class="list-name"
                multiple-lines
                :text="event.name"
                weight="bold"
                size="14px"
              />
              <div class="event-date-location-wrapper">
                <ar-text class="list-date" multiple-lines :text="event['date-string']" weight="normal" size="14px" />
                <ar-text
                  v-if="event.location"
                  class="list-date"
                  multiple-lines
                  text="•"
                  weight="normal"
                  size="14px"
                />
                <ar-text class="list-location" multiple-lines :text="event.location" weight="normal" size="14px" />
              </div>
            </div>
          </div>
          <div v-if="!noMatchingLinkLists" class="events-apply-deselect-wrapper">
            <div class="deselect-link-wrapper" @click="handleDeselectAll" :data-test-id="`deselect-all-events-link`">
              <ar-link-button class="deselect-link" text="Deselect" has-underline />
            </div>
            <div
              class="apply-button-wrapper"
              @click="handleApplyEventSelections"
              data-test-id="`apply-events-button`"
            >
              <ar-simple-button
                class="apply-button"
                text="Apply"
                weight="bold"
                :style="{
                  padding: '10px 16px 11px',
                  height: 'unset'
                }"
              />
            </div>
          </div>
          <div v-if="noMatchingLinkLists" class="link-list-item list no-match">
            <ar-text
              class="list-name"
              :style="{ margin: '0 16px' }"
              size="xs"
              text="No matching events found"
              multiple-lines
              :max-lines="1"
              line-height="20px"
              weight="normal"
            />
          </div>
        </div>
      </am2-field>

      <am2-field
        name="signup-form"
        label="Link a Signup form"
        class="suggested-audience__item"
      >
        <SearchMultiSelect
          :value="signupSelected"
          :items-list="signupForms"
          :is-loading="isFetchingSignupForms"
          @load-more="loadMoreSignupForms"
          @select="handleSignupFormSelect"
          @search="handleSignupFormSearch"
          data-test-id="suggested-audience-signup-form-list"
        >
          <template v-slot:default="{ item }">
            <ar-text
              class="list-name"
              multiple-lines
              :text="item.name"
              size="14px"
            />
          </template>
        </SearchMultiSelect>
      </am2-field>
    </div>
  </section>
</template>

<script>
import moment from 'moment-timezone';
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex';
import { displayDateRangeUSNoDay } from '@/utils/date/';
import dayjs from 'dayjs';
import ArrowHead from '~/assets/icons/arrow-head.svg?inline';
import SearchIcon from '~/assets/icons/search.svg?inline';
import SearchMultiSelect from '~/components/selects/search-multiselect/SearchMultiSelect.vue'

import accounting from 'accounting';
import {clone} from "@/utils/helpers";

export default {
  name: 'EventDetails',
  props: {
    updateSelectedLocation: {
      type: Function,
      default: null,
    },
    selectedLocationKey: {
      type: String,
      default: "physical",
    },
  },
  components: {
    SearchMultiSelect,
    ArrowHead,
    SearchIcon,
  },
  data() {
    return {
      displayEndTime: false,
      reasonHtmlCopy: 'Event detail updates that you have made on Audience Republic <u><b>will not</b></u> update the event details on Eventbrite. Eventbrite will need to be updated separately.',
      dontShowAgain: false,
      isUploadingEventImage: false,
      isEventRSVPEnabled: process.env.arEnableEventRSVP,
      arEnableSuggestedAudiences: process.env.arEnableSuggestedAudiences,

      // Purchased Tickets To
      suggestedAudienceOpen: false,
      eventsListObject: null,
      filteredEventsList: [],
      eventsListSearchText: '',
      eventsListOpen: false,
      linkInputId: 'linkInput',
      selectedEventOids: [],
      linkedEventsLocal: {},

      // Audience Tag
      tagOpen: false,
      tagSearchText: '',
      tagInputId: 'tagInput',

      signupSearch: '',
      signupSelected: [],
    };
  },
  computed: {
    ...mapState({
      currentEvent: state => state.event.currentEvent,
      connectZoomProviderInfo: state => state.event.connectZoomProviderInfo,
      scratchEvent: state => state.event.scratchEvent,
      isFetchingCurrentEventRSVPForm: state => state.eventRSVPForm.isFetchingCurrentEventRSVPForm,
      currentEventRSVPForm: state => state.eventRSVPForm.currentEventRSVPForm,
      scratchEventRSVPForm: state => state.eventRSVPForm.scratchEventRSVPForm,
      tagState: state => state.tag,
      signupForms: state => state.signupForm.signupForms,
      isNoMoreSignupForms: state => state.signupForm.isNoMoreSignupForms,
      isFetchingSignupForms: state => state.signupForm.isFetchingSignupForms,
    }),
    ...mapGetters({
      scratchEventImageUrl: 'event/scratchEventImageUrl',
      scratchEventIsZoomEvent: 'event/scratchEventIsZoomEvent',
      zoomUrlFromCurrentEvent: 'event/zoomUrlFromCurrentEvent',
      isFeatureEnabled: 'auth/isFeatureEnabled',
    }),
    startDateValidate() {
      let timeMustBeInFuture = false;
      // Make sure the event time is in the future if
      // 1. You're intending to connect the event with Zoom
      if (!!this.connectZoomProviderInfo) {
        timeMustBeInFuture = true;
      }
      const validate = {
        required: true,
        tzFormat: true,
        futureTime: timeMustBeInFuture,
      };
      return validate;
    },
    endDateValidate() {
      const validate = {
        tzFormat: true,
        notBeforeTime: {
          compareTime: this.scratchEvent.startDate,
          compareTimeName: 'event start time',
        },
      };
      return validate;
    },
    locationTabs() {
      return [
        {
          name: 'Physical Event',
          key: 'physical',
        },
        {
          name: 'Online Event',
          key: 'online',
        },
      ];
    },
    arS3BucketUploadOptionsList() {
      const list = [
        {
          assetType: 'event-image',
          campaignOid: null,
          eventOid: this.scratchEvent ? this.scratchEvent.oid : null,
        },
      ];
      if (this.currentEventRSVPForm) {
        // If it also has Event RSVP, let's also upload campaign image
        list.push({
          assetType: 'campaign-image',
          campaignOid: this.currentEventRSVPForm.oid,
          eventOid: null,
        });
      }
      return list;
    },

    selectedTimezone() {
      return this.scratchEvent?.timeZone || null
    },

    // EVENT DROPDOWN
    noMatchingLinkLists() {
      return !!this.eventsListSearchText.length && !this.filteredEventsList.length;
    },
    linkToPlaceholder() {
      return this.eventsListOpen ? 'Search events' : 'Select one or more events...'
    },
    eventInputValue() {
      return this.eventsListOpen ? this.eventsListSearchText : this.eventSelectionsText;
    },
    eventSelectionsText() {
      if (!this.selectedEventOidsLocal) {
        return ''
      }

      if (this.selectedEventOidsLocal.length === 1) {
        return '1 event selected';
      } else if (this.selectedEventOidsLocal.length > 1) {
        return `${this.selectedEventOidsLocal.length} events selected`;
      } else {
        return '';
      }
    },
    selectedEventOidsLocal() {
      if (!this.linkedEventsLocal) return {};
      let selected = Object.entries(this.linkedEventsLocal)
        .filter(([k, v]) => {
          if (v) return k;
        })
        .map(item => parseInt(item[0]));

      return selected;
    },


    // TAG DROPDOWN
    tagPlaceholder() {
      return this.tagOpen ? 'Search tags' : 'Select one or more tags...'
    },


    filteredTags() {
      return this.tagState.tags.filter((tag) => {
        return !this.selectedTagOids.includes(tag.oid)
      })
    },

    selectedTags() {
      const storedTagOids = clone(this.scratchEvent.presentation?.suggestedAudience?.tagOids || [])
      return this.tagState.tags.filter( item => storedTagOids.includes(item.oid))
    },

    selectedTagOids() {
      return this.selectedTags?.map((tag) => tag.oid)
    },

    isSuggestedAudiencesEnabled() {
      return !!this.arEnableSuggestedAudiences && this.isFeatureEnabled(['suggested-audience'])
    },
    tagInputValue() {
      return this.tagOpen ? this.tagSearchText : this.tagSelectionsText;
    },
    tagSelectionsText() {
      if (!this.selectedTagOids) {
        return ''
      }

      if (this.selectedTagOids.length === 1) {
        return '1 tag selected';
      } else if (this.selectedTagOids.length > 1) {
        return `${this.selectedTagOids.length} tags selected`;
      } else {
        return '';
      }
    },
  },
  watch: {
    scratchEvent: {
      handler(newVal, oldVal) {
        if (newVal.endDate && !this.displayEndTime) {
          this.displayEndTime = true;
        }

        if (newVal?.presentation?.suggestedAudience?.eventOids && newVal?.presentation?.suggestedAudience?.eventOids.length  && !oldVal?.presentation?.suggestedAudience?.eventOids) {
          this.linkedEventsLocal = newVal?.presentation?.suggestedAudience?.eventOids.reduce((acc, oid) => {
            acc[oid] = true
            return acc
          }, {});
          this.selectedEventOids = newVal?.presentation?.suggestedAudience?.eventOids
        }

        if (newVal?.presentation?.suggestedAudience?.signupFormOids && newVal?.presentation?.suggestedAudience?.signupFormOids.length  && !oldVal?.presentation?.suggestedAudience?.signupFormOids) {
          this.signupSelected = newVal?.presentation?.suggestedAudience?.signupFormOids
        }

        if (!!newVal?.oid && !oldVal?.oid) {
          this.fetchSuggestedAudienceResources();
        }

      },
      immediate: true
    },
  },
  methods: {
    ...mapActions({
      fetchEvents: 'FETCH_EVENTS',
      fetchEventsByOid: 'FETCH_EVENTS_BY_OID_AND_SEARCH',
      fetchTags: 'tag/FETCH_MORE_TAGS',
      fetchSignUps: 'signupForm/FETCH_SIGNUP_FORMS',
    }),
    ...mapMutations([
      'event/PATCH_SCRATCH_EVENT',
      'eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM',
      'signupForm/RESET_SIGNUP_FORMS',
    ]),
    deleteEventImage() {
      this['event/PATCH_SCRATCH_EVENT']({
        resources: this.scratchEvent.resources.filter(resource => resource.assetType !== 'event-image'),
      });
    },
    deleteRSVPEventImage() {
      this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
        resources: this.scratchEventRSVPForm.resources.filter(resource => resource.assetType !== 'campaign-image'),
      });
    },
    handleImageUpload({ arS3BucketResources }) {
      this.deleteEventImage();
      this['event/PATCH_SCRATCH_EVENT']({
        resources: this.scratchEvent.resources.concat([arS3BucketResources[0]]),
      });

      if (this.currentEventRSVPForm) {
        // It also has RSVP Event, let's also update scratch event rsvp form
        this.deleteRSVPEventImage();
        this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
          resources: this.scratchEventRSVPForm.resources.concat([arS3BucketResources[1]]),
        });
      }
    },
    handleImageDelete() {
      this.deleteEventImage();
      this.deleteRSVPEventImage();
    },
    handleEventNameInput(name) {
      this['event/PATCH_SCRATCH_EVENT']({ name });
    },
    handleEventLocationInput(location) {
      this['event/PATCH_SCRATCH_EVENT']({ location });
    },
    handleEventCapacityInput(capacity) {
      const newCapacity = capacity ? parseInt(capacity, 10) : null;
      this['event/PATCH_SCRATCH_EVENT']({ capacity: newCapacity });
    },
    handleEventStartDateInput(startDate) {
      this['event/PATCH_SCRATCH_EVENT']({ startDate });
    },
    handleEventEndDateInput(endDate) {
      this['event/PATCH_SCRATCH_EVENT']({ endDate });
    },
    handleEventTimeZoneInput(timeZone) {
      this['event/PATCH_SCRATCH_EVENT']({ timeZone });
    },
    handleEventLiveStreamUrlInput(liveStreamUrl) {
      this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl } });
    },

    handleLocationSelect(tab) {
      // this.selectedLocationKey = tab.key;
      this.updateSelectedLocation(tab.key);

      if (tab.key === 'physical') {
        this['event/PATCH_SCRATCH_EVENT']({ presentation: { liveStreamUrl: null } });

        if (this.currentEventRSVPForm) {
          this['eventRSVPForm/PUT_SCRATCH_EVENT_RSVP_FORM']({
            settings: {
              platform: null,
            },
          });
        }
      } else if (tab.key === 'online') {
        this['event/PATCH_SCRATCH_EVENT']({ location: null, capacity: null });
      }
    },

    handleAddEndTimeClick() {
      this.displayEndTime = true;
      if (this.scratchEvent.startDate && this.scratchEvent.startDate !== 'Invalid date') {
        const newEndDateFormat = moment.tz(this.scratchEvent.startDate, this.scratchEvent.timeZone)
          .add(2, 'hours')
          .utc()
          .format();
        this['event/PATCH_SCRATCH_EVENT']({
          endDate: newEndDateFormat,
        });
      }
    },

    handleRemoveEndTimeClick() {
      this.displayEndTime = false;
      this['event/PATCH_SCRATCH_EVENT']({ endDate: null });
    },

    handleUploadImageClick() {
      this.$refs['img-dropzone'].selectFile();
    },


    // EVENT DROPDOWN
    handleClickOutsideEventsListContainer(e) {
      if (!this.eventsListOpen) return;
      if (e.target.id === 'linkInput') return;

      let safeClasses = [
        'deselect-link-wrapper',
        'link-list-item list-item',
        'events-apply-deselect-wrapper',
        'apply-button-wrapper',
        'link-arrowhead-wrapper'
      ];
      if (safeClasses.includes(e.target.className)) return;

      this.eventsListSearchText = '';
      this.filteredEventsList = this.eventsListObject;
      this.eventsListOpen = false;
    },


    async fetchDropdownEvents() {
      let { rows } = await this.fetchEvents({
        orderBy: 'datecreated',
        top: '20',
        selectString: 'name,location,startDate'
      });

      let localTimezone = dayjs.tz.guess();

      let length = rows.length;
      let i = 0;
      let eventsListObject = {};
      while (i < length) {
        eventsListObject[rows[i].oid] = {
          oid: rows[i].oid,
          name: rows[i].name,
          location: rows[i].location,
          'date-string': displayDateRangeUSNoDay(
            dayjs(rows[i].startDate)
              .tz(localTimezone)
              .valueOf(),
            null,
            localTimezone
          )
        };

        i++;
      }

      this.filteredEventsList = Object.values(eventsListObject);
      this.eventsListObject = eventsListObject;
    },

    async searchEventsOnBackend(text) {
      let { rows } = await this.fetchEvents({
        orderBy: 'datecreated',
        top: 'all',
        selectString: 'name,location,startDate',
        searchString: text
      });
      let localTimezone = dayjs.tz.guess();

      let events = rows.map(item => {
        return {
          oid: item.oid,
          name: item.name,
          location: item.location,
          'date-string': displayDateRangeUSNoDay(
            dayjs(item.startDate)
              .tz(localTimezone)
              .valueOf(),
            null,
            localTimezone
          )
        };
      });
      this.filteredEventsList = events;
    },


    async searchTagsOnBackend(text) {
      let { rows } = await this.fetchTags({ searchString: text, reload: true });
      return rows
    },
    handleLinkArrowClick() {
      this.eventsListOpen = !this.eventsListOpen;
      if (this.eventsListOpen) {
        this.$refs.senderLinkListInput.focus();
      } else {
        if (this.selectedEventOidsLocal.length === 0) this.eventsListSearchText = '';
      }
    },

    async initSelectedEventsFromParams() {
      if (!this.$route.query.eventOid) return

      const eventOid = Number(this.$route.query.eventOid)
      const data = await this.fetchEventsByOid({oids: [eventOid]})

      if (data.length === 0) return
      let localTimezone = dayjs.tz.guess();
      const event = {
        oid: data[0].oid,
        name: data[0].name,
        location: data[0].location,
        'date-string': displayDateRangeUSNoDay(
          dayjs(data[0].startDate)
            .tz(localTimezone)
            .valueOf(),
          null,
          localTimezone
        )
      };

      this.eventFromParams = event

      this.linkedEventsLocal = Object.assign({}, this.linkedEventsLocal, { [eventOid]: true });

      if (!this.filteredEventsList.find(item => item.oid === eventOid)) {
        this.filteredEventsList.unshift(event);
        if (this.eventsListObject) {
          this.$set(this.eventsListObject, eventOid, event);
        } else {
          this.eventsListObject = { [eventOid]: event };
        }
      }
      this.linkedEventsLocal = Object.assign({}, this.linkedEventsLocal, { [eventOid]: true });
      this.selectedEventOids = data[0]?.presentation?.suggestedAudience?.eventOids || []
      this.selectedTags = data[0]?.presentation?.suggestedAudience?.tagOids || []
      this.signupSelected = data[0]?.presentation?.suggestedAudience?.signupFormOids || []
    },
    handleEventSelect(event) {
      this.$refs[`suggested-event-checkbox-${event.oid}`][0].toggle();
      this.eventsListObject[event.oid] = event;
    },
    handleLinkFocus() {
      this.eventsListOpen = true;
    },
    handleEventSearchInput(text) {
      this.eventsListSearchText = text;
      if (!text.length) {
        this.filteredEventsList = Object.values(this.eventsListObject);
      } else {
        this.searchEventsOnBackend(text);
      }
    },
    handleTagSearchInput(text) {
      this.tagSearchText = text;
      if (text?.length > 0) {
        this.searchTagsOnBackend(text);
      } else {
        this.fetchTags({
          top: 50,
          reload: true,
        })
      }
    },
    handleApplyEventSelections() {
      const selectedEventOids = clone(this.selectedEventOidsLocal || [])
      this.$emit('update', { sender: this.selectedSender, linkedEvents: this.selectedEventOidsLocal });
      this.eventsListOpen = false;
      this['event/PATCH_SCRATCH_EVENT']({
        presentation: {
          suggestedAudience: {
            eventOids: selectedEventOids,
          }
        }
      });
    },
    handleDeselectAll() {
      this.linkedEventsLocal = {};
      const selectedEventOids = clone(this.selectedEventOidsLocal || [])
      this['event/PATCH_SCRATCH_EVENT']({
        presentation: {
          suggestedAudience: {
            eventOids: selectedEventOids,
          }
        }
      });
    },


    // TAG
    handleClickOutsideTagContainer(e) {
      if (!this.tagOpen) return;
      if (e.target.id === 'tagInput') return;
      let safeClasses = [
        'deselect-link-wrapper',
        'link-list-item list-item',
        'events-apply-deselect-wrapper',
        'apply-button-wrapper',
        'link-arrowhead-wrapper'
      ];
      if (safeClasses.includes(e.target.className)) return;
      this.tagSearchText = '';
      // this.filteredEventsList = this.eventsListObject;
      this.tagOpen = false;
    },

    handleTagFocus() {
      this.tagOpen = true;
    },
    handleTagArrowClick() {
      this.tagOpen = !this.tagOpen;
      if (this.tagOpen) {
        this.$refs.tagInput.focus();
      } else {
        if (this.selectedTags.length === 0) this.tagSearchText = '';
      }
    },
    async handleTagSelect(tag) {
      const selectedTagOids = clone(this.scratchEvent.presentation?.suggestedAudience?.tagOids || [])
      selectedTagOids.push(tag.oid)
      this.tagOpen = false
      this['event/PATCH_SCRATCH_EVENT']({
        presentation: {
          suggestedAudience: {
            tagOids: selectedTagOids,
          }
        }
      });
    },
    handleRemoveAction(tagForRemoval) {
      const selectedTagOids = this.scratchEvent.presentation?.suggestedAudience?.tagOids || []
      const newSelectedTagOids = selectedTagOids.filter((tag) => {
        return tag !== tagForRemoval.oid
      })
      this['event/PATCH_SCRATCH_EVENT']({
        presentation: {
          suggestedAudience: {
            tagOids: newSelectedTagOids,
          }
        }
      });
    },
    formatNumber(item) {
      return accounting.formatNumber(item)
    },
    // Fetch events, tags, signupforms related to suggested Audience
    async fetchSuggestedAudienceResources() {
      const tagOids = clone(this.scratchEvent.presentation?.suggestedAudience?.tagOids || [])
      const eventOids = clone(this.scratchEvent.presentation?.suggestedAudience?.eventOids || [])
      const signupFormOids = clone(this.scratchEvent.presentation?.suggestedAudience?.signupFormOids || [])

      // First fetch pre-selected tags
      // Then fetch all of the page tags
      if (tagOids.length > 0) {
        await this.fetchTags({
          reload: true,
          oids: tagOids,
        })
      }

      this.fetchTags({
        top: 50,
        reload: true,
      })

      // TODO - this logic needs a lot of cleanup
      this.fetchDropdownEvents();
      if (eventOids.length > 0) {
        const selectedEvents = await this.fetchEventsByOid({oids: eventOids})
      }
    },
    reloadSignupForms() {
      this.fetchSignUps({
        append: false,
        searchString: this.signupSearch
      });
    },

    loadMoreSignupForms(searchText = '') {
      if (this.isFetchingSignupForms || this.isNoMoreSignupForms) return;

      this.fetchSignUps({
        append: true,
        searchString: this.signupSearch,
      });
    },

    handleSignupFormSelect(arraysOfIds) {
      const selected = arraysOfIds.map(item => Number(item))
      this.signupSelected = selected;
      this['event/PATCH_SCRATCH_EVENT']({
        presentation: {
          suggestedAudience: {
            signupFormOids: selected,
          }
        }
      });
    },

    handleSignupFormSearch(searchText) {
      if (!searchText !== this.signupSearch) {
        this.signupSearch = searchText;
        this.reloadSignupForms()
      }
    },
  },

  created() {
    this.fetchDropdownEvents()
    this.reloadSignupForms()
  },

  mounted() {
    this.initSelectedEventsFromParams()
  },

  beforeDestroy() {
    this['signupForm/RESET_SIGNUP_FORMS']()
  }
};
</script>

<style lang="scss" scoped>
.save-event-modal {
  .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 50px;
    padding: 28px 28px;
    .link-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 24px;
      padding-bottom: 10px;
    }
  }
}

.suggested-audience-section {
  margin-top: 32px;
  border: 1px solid $skyBlueGrey500;
  border-radius: 0.25rem;
  padding: 24px;

  .heading {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .heading-text {
      margin-left: 8px;
    }
  }


  .suggested-audience__item {
    margin-top: 22px;


    .list-items-wrapper {
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.07);
      max-width: 710px;
      background: white;
      position: relative;
      top: 0px;
      z-index: 1;
      max-height: 181px;
      overflow: auto;

      &.dropdown-list-items-wrapper {
        max-height: 232px;
      }

      .list-item {
        padding: 12px 20px;
        border-bottom: 1px solid $blueGrey500;
        width: 100%;
        cursor: pointer;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        &:hover {
          background: #f5f1ff;
        }

        &.no-match {
          cursor: default;
        }

        &:last-child {
          border-bottom: unset;
        }

        & > * {
          pointer-events: none;
        }
      }

      .link-list-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 12px 0;
        width: 100%;
        cursor: pointer;

        &:hover {
          background: #f5f1ff;
        }

        &:first-child {
          border-top: unset;
        }

        &.no-match {
          cursor: default;

          &:hover {
            background: white;
          }
        }

        & > * {
          pointer-events: none;
        }

        .dropdown-item-checkbox {
          margin-top: 4px;
          margin-left: 16px;
        }

        .dropdown-copy-wrapper {
          pointer-events: none;
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          margin-left: 15px;

          .event-date-location-wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .list-date {
              color: $skyBlueGrey700;
              margin-right: 5px;
            }
            .list-location {
              color: $skyBlueGrey700;
            }
          }

          &.tag-copy-wrapper {
            width: 100%;
            flex-flow: row;
            justify-content: space-between;
            align-items: center;
            margin-right: 15px;
          }
        }

        &.selected {
          background: #f5f1ff;
        }
      }

      .events-apply-deselect-wrapper {
        position: sticky;
        bottom: 0;
        padding: 16px 12px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #dcdee4;
        background: white;

        .deselect-link-wrapper {
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }

        .apply-button-wrapper {
          cursor: pointer;

          & > * {
            pointer-events: none;
          }
        }
      }
    }
  }



  .input-search-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    .fake-input-wrapper {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 17px 19px 17px 15px;
      border: 1px solid $blueGrey500;
      border-radius: 4px;
      cursor: pointer;
      position: relative;

      &.event-input-container {
        flex-flow: row wrap;

        .subheading {
          width: 100%;
          margin-top: 8px;
        }
      }

      &.invalid-warning {
        border: 1px solid $red500;
        box-shadow: 0px 0px 0px 3px $red400;
      }

      .fake-text-area-wapper {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
      }

      .arrowhead-wrapper {
        position: absolute;
        right: 0;
        height: 50px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 20px;
        top: 0;

        & > * {
          pointer-events: none;
        }

        .send-to-input-dropdown-arrowhead {
          transition: all 0.2s ease;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }

    .input-arrowhead-wrapper {
      width: 100%;
      position: relative;
      cursor: pointer;

      .search-icon-wrapper {
        z-index: 1;
        height: 100%;
        position: absolute;
        left: 13px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .search-icon {
          opacity: 0.4;
        }
      }

      .tag-wrapper {
        border: 1px solid $skyBlueGrey500;
        padding: 11px 12px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      .arrowhead-wrapper {
        position: absolute;
        right: 0;
        height: 50px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 20px;
        top: 0;

        & > * {
          pointer-events: none;
        }

        .send-to-input-dropdown-arrowhead {
          transition: all 0.2s ease;

          &.rotate {
            transform: rotate(180deg);
          }
        }
      }

      .search-icon-wrapper {
        z-index: 1;
        height: 100%;
        position: absolute;
        left: 13px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;

        .search-icon {
          opacity: 0.4;
        }
      }

      .sender-arrowhead-wrapper,
      .link-arrowhead-wrapper {
        position: absolute;
        right: 0;
        height: 50px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 20px;
        top: 0;

        & > * {
          pointer-events: none;
        }

        .send-to-input-dropdown-arrowhead {
          &.rotate {
            transform: rotate(180deg);
          }
        }
      }
    }

    .filter-button-wrapper {
      position: relative;

      .filter-count-circle-wrapper {
        position: absolute;
        top: 1px;
        right: 1px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 7.5px;
        background: $purple500;
        transform: translateX(50%) translateY(-50%);
        color: white;
        z-index: $zIndexHigh;

        .filter-recipient-count {
          position: relative;
          font-size: 10px;
          line-height: 10px;
          top: -1.5px;
          left: 0.2px;
        }
      }
    }
  }

  .dropdown-list-items-wrapper {
    max-height: 329px;
  }
}
</style>
