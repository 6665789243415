<template>
  <div
    class="settings-email-send-limit"
  >
    <am2-email-send-limit-modal
      :is-show="isEmailLimitModalOpen"
      @revert="handleModalCancel"
      @confirm="handleModalConfirm"
    />
    <div
      class="headings u-margin-bottom-7"
    >
      <div>
        <ar-text
          size="md"
          text="Email send limit"
          class="u-margin-bottom-2"
        />
        <ar-text
          size="xs"
          text="Limit the number of emails recipients receive in a set timeframe."
          :style="{
            color: $arStyle.color.skyBlueGrey700,
          }"
        />
      </div>
    </div>
    <div
      class="email-send-limit-card"
    >
      <am2-card-container
        :style="{ padding: $arMediaQuery.pageContent.maxWidth('sm') ? '20px' : '24px 30px' }"
        layout="soft"
      >
        <div class="card-main-switch">
          <ar-text
            class="u-margin-top-1"
            size="xs"
            text="Enable email send limit"
            weight="bold"
          />
          <am2-switch
            :size="'xs'"
            :value="emailLimitEnabled"
            @input="emailLimitEnableStateChanged"
            data-test-id="`email-send-limit-enable-switch`"
          />
        </div>
        <template v-if="emailLimitEnabled">
          <div v-if="!emailLimitIsEditing" class="card-edit-summary u-margin-top-3 u-padding-top-5">
            <ar-text
              size="xs"
              :text="`A recipient can receive ${emailLimitNumber} email every ${emailLimitTimeRange} ${emailLimitTimeRange > 1 ? emailLimitTimeUnit : emailLimitTimeUnit.slice(0, -1)}`"
            />
            <ar-link-button
              text="edit"
              :has-underline="true"
              :color="$arStyle.color.$purple500"
              @click="handleEditEmailLimit"
              data-test-id="`email-send-limit-edit-button`"
            />
          </div>
          <div v-else class="card-edit-section u-margin-top-3 u-padding-top-5">
            <div class="form-row">
              <ar-text
                size="xs"
                text="Recipients can only receive"
                weight="bold"
              />
              <ar-input
                type="number"
                :step="1"
                :min="1"
                :max="100"
                integer
                v-model="emailLimitNumber"
                :style="{ width: '86px', height: '36px' }"
                data-test-id="`email-send-limit-number-input`"
              />
              <ar-text
                size="xs"
                text="emails every"
                weight="bold"
              />
              <ar-input
                type="number"
                v-model="emailLimitTimeRange"
                :step="1"
                :min="1"
                :max="48"
                integer
                :style="{ width: '86px', height: '36px' }"
                data-test-id="`email-send-limit-range-input`"
              />
              <ar-simple-select
                class="select"
                :style="{ width: '150px', height: '36px' }"
                :items="timeUnits"
                :default-select-index="timeUnits.findIndex((u)=>u.value === emailLimitTimeUnit)"
                data-test-id="`email-send-limit-unit-input`"
                @select="handleTimeUnit"
              />
              <ar-icon
                class="icon"
                name="exclamation"
                width="16px"
                v-tooltip.bottom="'The timeframe is a rolling period, not a fix calendar period. For example, if the limit is 1 email per 24 hours, a recipient who receives an email at 3 PM won\'t receive another until after 3 PM the next day.'"
              />
            </div>
            <div v-if="!emailLimitNumber || emailLimitNumber <= 0" class="snackbar-row u-margin-top-3">
              <ar-icon
                class="icon"
                name="exclamation"
                color="red"
                width="16px"
              />
              <ar-state-message
                type="error"
                text="Enter a valid number greater than zero for the email limit."
                :style="{fontSize: '12px'}"
              />
            </div>
            <div class="checkbox-row u-margin-top-4">
              <ar-checkbox
                label="Include transactional messages"
                align="left"
                v-model="includeTransactionalMessages"
                data-test-id="`email-send-limit-include-transactional-checkbox`"
              />
              <ar-icon
                class="icon"
                name="exclamation"
                width="16px"
                v-tooltip.bottom="'Transactional messages are sent based on user actions, not from a marketing list (e.g., event updates or campaign notifications).'"
              />
            </div>
            <div class="buttons-row u-margin-top-10">
              <ar-simple-button
                text="Save"
                weight="bold"
                :style="{
                  padding: '10px 16px 11px',
                  height: 'unset'
                }"
                :disabled="!emailLimitTimeUnit || !emailLimitNumber || emailLimitNumber <= 0 || !emailLimitTimeRange || emailLimitTimeRange <= 0"
                @click="handleSave"
                data-test-id="`email-send-limit-save-button`"
              />
              <ar-link-button
                text="Cancel"
                has-underline
                @click="handleCancel"
                data-test-id="`email-send-limit-cancel-link`"
              />
            </div>
          </div>
        </template>
      </am2-card-container>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "EmailSendLimit",
  data(){
    return {
      configExists: false,
      configOid: null,
      emailLimitEnabled: false,
      emailLimitIsEditing: false,
      emailLimitNumber: 1,
      emailLimitTimeRange: 24,
      emailLimitTimeUnit: `hours`,
      timeUnits: [
        {value: 'hours', name: 'Hours'},
        {value: 'days', name: 'Days'},
      ],
      includeTransactionalMessages: false,
      isEmailLimitModalOpen: false,
    }
  },
  async mounted(){
    this.setConfig();
  },
  methods: {
    ...mapActions([
      'auth/FETCH_EMAIL_SEND_LIMIT_SETTINGS',
      'auth/CREATE_EMAIL_SEND_LIMIT_SETTINGS',
      'auth/UPDATE_EMAIL_SEND_LIMIT_SETTINGS',
    ]),
    async setConfig(){
      const remoteConfig = await this['auth/FETCH_EMAIL_SEND_LIMIT_SETTINGS']();
      if(remoteConfig){
        const config = remoteConfig.additionalInfo;
        this.configExists = true;
        this.configOid = remoteConfig.oid
        this.emailLimitEnabled = config.enabled;
        this.emailLimitNumber = config.limit;
        this.emailLimitTimeRange = config.range >= 24 && config.range % 24 === 0 ? config.range / 24 : config.range;
        this.emailLimitTimeUnit = config.range >= 24 && config.range % 24 === 0 ? 'days' : 'hours';
        this.includeTransactionalMessages = config.includeTransactional;
      }
    },
    handleModalCancel(){
      this.isEmailLimitModalOpen = false;
      this.emailLimitEnabled = true;
    },
    handleModalConfirm(){
      this.isEmailLimitModalOpen = false;
      this.emailLimitEnabled = false;
      this.emailLimitIsEditing = false;
      this.saveConfig();
    },
    emailLimitEnableStateChanged(state){
      if(!state){
        this.emailLimitEnabled = false;
        this.isEmailLimitModalOpen = true
      }else{
        this.emailLimitEnabled = true;
        this.saveConfig();
      }
    },
    handleEditEmailLimit(){
      this.emailLimitIsEditing = true;
    },
    handleTimeUnit(value){
      this.emailLimitTimeUnit = value.value;
    },
    handleSave(){
      this.saveConfig();
      this.emailLimitIsEditing = false;
    },
    handleCancel(){
      this.setConfig();
      this.emailLimitIsEditing = false;
    },
    async saveConfig(){
      let config = {
        enabled: this.emailLimitEnabled,
        limit: parseInt(this.emailLimitNumber),
        range: parseInt((this.emailLimitTimeUnit === 'days' ? this.emailLimitTimeRange * 24 : this.emailLimitTimeRange)),
        includeTransactional: this.includeTransactionalMessages,
      };
      if(!this.configExists){
        this['auth/CREATE_EMAIL_SEND_LIMIT_SETTINGS']({
          property: config
        })
      }else{
        this['auth/UPDATE_EMAIL_SEND_LIMIT_SETTINGS']({
          propertyOid: this.configOid,
          property: config
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">

.settings-email-send-limit {
  max-width: 800px;
  margin: 59px auto 0;

  .headings {
    display: flex;
    justify-content: space-between;
  }

  .card-main-switch {
    display: flex;
    justify-content: space-between;
  }

  .card-edit-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border-top: 1px solid #d8dde3;
  }

  .card-edit-section {
    border-top: 1px solid #d8dde3;

    .form-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
    }

    .snackbar-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
    }

    .checkbox-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
    }

    .buttons-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      .email-send-limit-save-button{
        padding: 10px 16px 11px;
        border: 1px solid $purple500;
        border-radius: 4px;
      }

    }

  }

}
</style>
