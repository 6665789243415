import {mapActions} from 'vuex';
export default ({
  data(){
    return {
      emailSendLimitEnabled: false,
      ignoreEmailSendLimit: false,
    }
  },
  async mounted(){
    const remoteConfig = await this['auth/FETCH_EMAIL_SEND_LIMIT_SETTINGS']();
    if(remoteConfig){
      const config = remoteConfig.additionalInfo;
      this.emailSendLimitEnabled = config.enabled;
    }
  },
  watch:{
    scratchEmailMessage(){
      this.ignoreEmailSendLimit = this.item?.config?.['ignore-email-send-limit'] || this.scratchEmailMessage?.meta?.ignoreEmailSendLimit || false;
    },
  },
  computed: {

  },
  methods: {
    ...mapActions([
      'auth/FETCH_EMAIL_SEND_LIMIT_SETTINGS',
    ]),
  }
})
